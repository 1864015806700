#rebrand {    
    .btn {
        position: relative;
        overflow: hidden; 
        width: 100%;
        height: 40px; 
        border-radius: 100px; 
        padding: 12px 20px;
        font-family: var(--font-rebrand);
        font-size: 14px;
        font-weight: 700; 
        line-height: 1.2em;
        text-transform: uppercase; 
        text-align: center;

        &--smaller {
            width: unset;
        }
    }
  

    .btn-primary { 
        background: var(--redesign-color-primary); 
        color: var(--color-white); 

        &--white-border {
            border: 1px solid var(--color-white);
        }
    } 

    .btn-secondary { 
        background: var(--color-white); 
        color: var(--redesign-color-primary); 
        border: 1px solid var(--redesign-color-primary);

        &:hover, &:focus { 
            color: var(--redesign-color-green-dk); 
            border: 1px solid var(--redesign-color-green-dk); 
        }
    }

    .btn-tertiary {
        color: var(--redesign-color-primary);
        background-color: var(--redesign-color-white-md);

        &:focus, &:hover { 
            color: var(--redesign-color-green-dk);
        }
    }

    .btn.disabled, .btn:disabled {
        cursor: url(/images/disabled-btn-pointer.svg) 16 16, auto;
    }

    .btn.btn-primary.disabled, .btn.btn-primary:disabled {
        background-color: var(--color-gray-dk);
    }

    .btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
        border-color: var(--color-gray-dk);
        color: var(--color-gray-dk);
        background-color: var(--color-white);
    }

    .btn.btn-tertiary.disabled, .btn.btn-tertiary:disabled {
        background-color: var(--color-white-md);
        color: var(--color-gray-dk);
    }

    .btn .btn-effect {
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: center;
        transform: translate(-100%, 70%);
        border-radius: 50%;
        opacity: 1;
        pointer-events: none;
        animation: rippleEffect 0.8s ease-out forwards;
        animation-name: rippleEffect;
    }

    .btn-primary .btn-effect {
        background-color: var(--redesign-color-green-dk);
    }

    .btn-secondary .btn-effect {
        background-color: var(--redesign-color-white-md);
    }

    .btn-tertiary .btn-effect {
        background-color: rgba(153, 207, 40, 0.2);
    }

    .btn-text-wrapper {
        position: relative;
        z-index: 2; 
    }

    .btn-top {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        padding: 10px 16px;
        background-color: var(--color-white-md);
        border: 1px solid var(--redesign-color-border);
        color: var(--color-black);
        font-size: 12px;
        line-height: 1.2em;
        z-index: 1;
        cursor: pointer;
    
        @media(--breakpoint-tablet-up) {
            display: none;
        }
    }

    .btn-pulsating {
        --middle-color: #7ba919;

        padding-right: 55px;
        &__animated-icon {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-55%);
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 55px;
            font-weight: 700;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -200%;
            display: block;
            width: 200%;
            height: 100%;
            background: linear-gradient( 90deg, var(--redesign-color-primary) 0%, var(--middle-color, #7ba919) 30%, var(--redesign-color-primary) 100%);
            transform: translateY(-50%);
            opacity: 0;
            transition: left 1.7s ease-in-out;
        }

    }

    .btn-pulsating.error {
        span {
            padding-left: 0;
        }

        .btn-pulsating__animated-icon {
            display: none;
        }
    }

    .btn-pulsating.active {
        &::before {
            left: 100%;
            opacity: 1;
        }
    }
}

@keyframes rippleEffect {
    0% {
        transform: translate(-100%, 70%);
        opacity: 1;
    }
    100% {
        transform: translate(0, 50%);
        opacity: 0;
    }
}