#rebrand {
  .successful-reset-password {
    position: relative;
    padding: 160px 0;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__image {
      max-width: 70px;
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      object-fit: cover;
    }

    &__title {
      margin-bottom: 8px;
      // todo: add font-family
      font-size: 18px;
      font-weight: 600;
      color: #251f20;
    }

    &__description {
      max-width: 364px;
      width: 100%;
      font-size: 14px;
      color: #251f20;
      text-align: center;
    }

    @media (--breakpoint-tablet-sm) {
      padding: 140px 0;
    }
  }
}
