#rebrand {
  .products-listing-sidebar {
    position: relative;

    .sidebar {
      margin-right: 15px;

      .filters-aside {
        margin-right: 0;
      }
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
    }

    &__filters {
      display: flex;
      align-items: start;
      gap: 30px;

      .filter-total__element {
        flex-wrap: wrap;
        row-gap: 15px;

        @media (--breakpoint-tablet-sm) {
          flex-wrap: nowrap;
        }
      }

      @media (--breakpoint-tablet-sm) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 5px;

      @media (--breakpoint-desktop) {
        display: none;
      }
    }

    &__counter {
      display: none;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 18px;
      font-size: 12px;
      color: var(--color-white);
      background-color: var(--redesign-color-primary);
      border-radius: 100px;

      &.active {
        display: flex;
      }
    }

    &__title {
      font-size: 20px;
      color: var(--redesign-color-black-md);
    }

    &__button {
      button {
        height: auto;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 0;
        font-size: 14px;
        font-weight: 700;
        color: var(--redesign-color-primary);
        white-space: nowrap;
        text-transform: uppercase;
        overflow: visible;
      }

      @media (--breakpoint-desktop) {
        display: none;
      }
    }

    &__categories {
      @media (--breakpoint-desktop) {
        display: none;

        &.active {
          display: block;
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          overflow: hidden;
        }
      }
    }
  }
}
