.promo-timer {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    min-width: 182px;
    background-color: var(--redesign-color-primary);
    border-radius: 10px 10px 0 0;
    font-size: 24px;
    line-height: 1.2em;
    color: var(--color-white);
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 21;

    &__count {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 0 16px;
        user-select: none;
        
        @media (--breakpoint-tablet-up) {
            padding: 0 30px 0 40px;
        }
    }

    &__trigger {
        height: 100%;
        padding: 25px 14px 22px 14px;
        border-radius: 0 10px 0 0;
        background-color: var(--redesign-color-green-dk);
        border: 0;
        cursor: pointer;

        svg {
            transform: rotate(45deg);
        }
    } 

    &__expanded-content {
        display: none;
    }

    &.expand {
        padding: 39px 16px 20px 16px;
    }

    &.expand .promo-timer__trigger {
        position: absolute;
        top: 0;
        right: 0;
        height: unset;
        background-color: transparent;

        svg {
            transform: rotate(225deg);
        }
    }

    &.expand .promo-timer__expanded-content {
        display: block;
        padding: 40px 30px 20px;
        text-align: center;
    }

    &.expand .promo-timer__count {
        display: none;
    }

    &.active {
        transform: translateY(0);
    }
    
    @media(--breakpoint-tablet-up) {
        min-width: 238px;
        font-size: 28px;
        left: 36px;
    }
}