.login-popup {
	position: absolute;
	top: calc(100% + 12px);
	right: 0;
    opacity: 1;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 8px 16px;
	min-width: 180px;
	border: 1px solid var(--redesign-color-border);
	background-color: var(--color-white);
	font-weight: 600;
	color: var(--color-black);
	transform: translateX(23%);
	border-radius: 8px;
	pointer-events: none;
    transition: opacity 0.8s ease-in-out;

	&::after {
		content: '';
		position: absolute;
		top: -8px;
		left: 70%;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 6px 8px 6px;
		border-color: transparent transparent var(--color-white) transparent;
	}

	&::before {
		content: '';
		position: absolute;
		top: -9px;
		left: calc(70% - 1px);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 7px 9px 7px;
		border-color: transparent transparent var(--redesign-color-border) transparent;
		z-index: -1;
	}

    &--hidden {
        opacity: 0;
    }

	@media(--breakpoint-tablet-up) {
		display: flex;
	}
}