
.checkout-progression {
    padding: 10px 0;
    background-color: var(--color-white);

    &__list {
        display: flex;
        gap: 30px;
        padding-left: 12px;
    }

    &__step {
        font-weight: 600;
        font-size: 12px;
        line-height: 1.2em;

        &--prev {
            color: var(--color-black);

            a {
                color: var(--color-black);
            }
        }

        &--curr {
            color: var(--redesign-color-primary);

            a {
                color: var(--redesign-color-primary);
            }
        }

        &--next {
            color: var(--color-gray-dk);

            a {
                color: var(--color-gray-dk);
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    @media(--breakpoint-tablet-up) {
        padding: 16px 0;
        border-bottom: 1px solid var(--redesign-color-border);
    }
}