.no-results {
    background-color: var(--color-white-md);

    &__head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 35px;
        min-height: 230px;
    }

    &__content {
        padding-top: 80px;
        border-radius: 40px 40px 0 0;
        background-color: var(--color-white);
    }

    &__message {
        color: var(--color-black);
        text-align: center;
        font-size: 24px;
        line-height: 1.2;
        font-weight: 600;

        @media(--breakpoint-tablet-up) {
            font-size: 28px;
        }
    }
}