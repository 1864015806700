#rebrand {
  .products-listing-boxes {
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
        border-bottom: 1px solid var(--color-scrollbar-bg);
      }
    }

    &__button {
      max-width: 194px;
      margin: 0 auto;
      padding: 60px 0 80px;

      &.hidden {
        display: none;
      }

      button {
        border-radius: 100px;
        font-size: 14px;
      }

      @media (--breakpoint-tablet-sm) {
        max-width: 100%;
        padding: 40px 16px;
      }
    }

    .box-basic {
      max-width: 310px;
      width: 100%;
      outline: none;
      border: 1px solid var(--redesign-color-border);
      border-radius: 10px;

      @media (--breakpoint-tablet-sm) {
        min-width: 180px;
        border: 0;

        &:nth-of-type(odd) {
          border-right: 1px solid var(--color-scrollbar-bg);
          border-radius: 0;
        }
      }
    }

    .box-type-2 {
      display: flex;
      flex-direction: column;
      padding: 19px 25px 0;

      .content {
        margin-top: auto;
      }

      @media (--breakpoint-tablet-sm) {
        position: relative;
        padding: 10px 10px 0;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: var(--color-scrollbar-bg);
        }
      }
    }

    .ribbon {
      padding: 0;
      left: 10px;

      @media (--breakpoint-tablet-sm) {
        padding: 0;
        left: 0;
      }
    }

    .title {
      margin-bottom: 12px;
    }

    .box-price-wrap {
      border-color: var(--redesign-color-border);
    }

    .box-price-wrap {
      text-align: left;
    }

    .box-price {
      vertical-align: unset;
    }

    @media (--breakpoint-tablet-sm) {
      margin: 0 -10px;

      .promo-price {
        font-size: 18px;

        span {
          font-size: 14px;
        }
      }

      .old-price {
        font-size: 16px;

        span {
          font-size: 12px;
        }
      }
    }
  }
}
