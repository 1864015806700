#rebrand {
  .newsletter {
    position: relative;
    margin-bottom: 50px;
    border-radius: 10px;
    background-image: url("../../images/banner-desktop.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &.hidden {
      display: none;
    }

    &__container {
      padding: 20px;
    }

    &__content {
      max-width: 361px;
      width: 100%;
    }

    &__close {
      position: absolute;
      width: 14px;
      height: 14px;
      top: 12px;
      right: 12px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      color: var(--color-white);
      
      @media (--breakpoint-tablet-sm) {
        max-width: 207px;
        width: 100%;
        margin-bottom: 8px;
        font-size: 18px;
      }
    }

    &__description {
      margin-bottom: 20px;
      font-size: 14px;
      color: var(--color-white);
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 12px;

      &-subscribe,
      &-close {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 140px;
        width: 100%;
        padding: 9px 0;
        font-size: 14px;
        font-weight: 700;
        border-radius: 100px;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
        }

        @media (--breakpoint-tablet-sm) {
          max-width: 100%;
        }
      }

      &-subscribe {
        color: var(--redesign-color-primary);
        background-color: var(--color-white);
      }

      &-close {
        color: var(--color-white);
        border: 1px solid var(--color-white);
      }

      @media (--breakpoint-tablet-sm) {
        gap: 8px;
      }
    }

    @media (--breakpoint-tablet-sm) {
      height: 320px;
      background-image: url("../../images/banner-mobile.png");
    }
  }
}
