#rebrand {
  .profile-address-form {
    &__wrapper {
      display: grid;
      grid-template-columns: 300px auto;
      column-gap: 30px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
      }
    }

    &__container {
      padding-top: 35px;
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 10px;
      font-size: 14px;
      color: var(--color-gray-dk);
    }

    &__title {
      margin-bottom: 24px;
      font-size: 28px;
      font-weight: 600;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet-sm) {
        font-size: 24px;
      }
    }

    &__form {
      &-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 28px;

        @media (--breakpoint-tablet-sm) {
          grid-template-columns: 1fr;
        }
      }

      &-buttons {
        display: flex;
        align-items: center;
        gap: 16px;

        @media (--breakpoint-tablet-sm) {
          flex-direction: column;
          gap: 20px;
        }
      }

      &-add-btn,
      &-remove-btn {
        max-width: 207px;
        width: 100%;
        padding: 12px 0;
        font-size: 14px;
        font-weight: 700;
        border-radius: 100px;
        text-transform: uppercase;

        @media (--breakpoint-tablet-sm) {
          max-width: 100%;
        }
      }

      &-add-btn {
        color: var(--color-white);
        background-color: var(--redesign-color-primary);
        border: 1px solid transparent;
      }

      &-remove-btn {
        color: var(--redesign-color-red);
        border: 1px solid var(--redesign-color-red);
        background-color: transparent;
      }

      .form-row:last-child {
        grid-column: span 2;

        @media (--breakpoint-tablet-sm) {
          grid-column: unset;
        }
      }

      .field {
        input {
          height: 40px;
          padding: 12px 20px;
          border-radius: 100px;

          &::placeholder {
            font-size: 14px;
            color: var(--color-gray-dk);
          }
        }
      }

      .form-label {
        display: block;
        padding-left: 12px;
        margin-bottom: 5px;
        color: var(--color-gray-dk);
        text-transform: none;
      }
    }

    @media (--breakpoint-tablet-sm) {
      margin-bottom: 50px;
    }
  }
}
