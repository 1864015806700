.order-review {
    padding-bottom: 70px;

    &__head {
        padding: 20px 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2em;

        @media(--breakpoint-tablet-up) {
            padding-bottom: 0;
        }
    }

    &__content {
        padding-top: 20px;
        background-color: var(--color-white-md);

        @media(--breakpoint-tablet-up) {
            background-color: var(--color-white);
            padding-top: 0;
        }
    }

    &__info-table {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        margin-bottom: 20px;
        background-color: var(--color-white);
        border-radius: 10px;
    }

    &__item-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 40vh;
        margin-bottom: 16px;
        overflow: auto;

        @media(--breakpoint-tablet-up) {
            display: none;
        }
    }

    &__result {
        padding-top: 16px;
        border-top: 1px solid var(--redesign-color-border);

        @media(--breakpoint-tablet-up) {
            display: none;
        }
    }

    &__result-row {
        display: flex;
        justify-content: space-between;
        color: var(--redesign-color-black-md);
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2em;

        &:last-child {
            padding-top: 16px;
        }

        &:not(:last-child, :first-child) {
            padding-top: 8px;
        }
    }

    &__sum {
        font-weight: 700;
        font-size: 18px;

        span {
            font-size: 14px;
        }
    }

    &__btn-container {
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 16px;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 21;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--redesign-color-black-md);
        font-weight: 600;
        text-transform: uppercase;
    }

    &__finish-btn {
        display: none;
        align-self: flex-end;

        @media(--breakpoint-tablet-up) {
            display: block;
        }
    }
}

.order-review-item {
    padding: 16px 0;
    background-color: var(--color-white);
    color: var(--color-gray-dk);

    &__head {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-bottom: 20px;
        font-weight: 600;
        color: var(--redesign-color-black-md);
        text-transform: uppercase;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 6px;

        @media(--breakpoint-tablet-up) {
            gap: 8px;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        span {
            color: var(--redesign-color-black-md);
            font-weight: 600;
        }

        @media(--breakpoint-tablet-up) {
            font-size: 16px;
            line-height: 1.5em;
        }
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--redesign-color-border);
    }
}