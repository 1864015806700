.order-finish {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    color: var(--color-black);

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__head {
        max-width: 320px;
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2em;
    }

    &__info {
        max-width: 360px;
    }
}