#rebrand {
  .footer {
    overflow: hidden;
    width: 100%;
    font-size: 13px;
    line-height: 1.3;
    background: #f7f7f7;
    color: #939393;

    &__top {
      position: relative;
      padding: 25px 15px;

      &-container {
        display: grid;
        grid-template-columns: 1fr 2fr;

        @media (--breakpoint-desktop) {
          grid-template-columns: 1fr;
          padding: 0 16px;
        }
      }

      @media (--breakpoint-desktop) {
        padding: 40px 0 0;
      }
    }

    &__image {
      > img {
        display: block;
        max-width: 150px;
        width: 100%;
        margin-bottom: 20px;
      }

      @media (--breakpoint-desktop) {
        margin-bottom: 40px;
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      gap: 10px;

      a:hover {
        text-decoration: none;
      }

      &-item {
        display: flex;
        align-items: end;
        gap: 8px;
        font-family: var(--font-rebrand);
        font-size: 14px;
        line-height: 16px;
        color: #251f20;

        > svg {
          display: block;
          width: 20px;
          height: 20px;
        }
      }

      @media (--breakpoint-desktop) {
        flex-direction: row;
        padding: 0;
        max-width: 100%;

        a {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-right: 15px;
        }

        > svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 30px;

      &-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        border: 0;
        background: transparent;

        &.active {
          margin-bottom: 12px;

          svg {
            transform: rotate(180deg);
          }
        }

        svg {
          display: none;
        }

        @media (--breakpoint-tablet-sm) {
          width: 100%;
          padding-top: 20px;
          margin-bottom: 0;
          cursor: pointer;

          svg {
            display: block;
          }
        }
      }

      &-item {
        font-family: var(--font-rebrand);
        font-feature-settings: "aalt" 1;

        @media (--breakpoint-tablet-sm) {
          padding-bottom: 20px;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: #dbdbdb;
          }
        }
      }

      &-title {
        font-family: var(--font-rebrand);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.3;
        color: #251f20;
        text-transform: uppercase;
        font-weight: normal;
      }

      &-links {
        display: flex;
        flex-direction: column;
        gap: 6px;
        list-style: none;
        padding-left: 0;

        li {
          padding-bottom: 2px;
        }

        a {
          font-size: 14px;
          line-height: 16px;
          color: #251f20;

          &:hover {
            padding-left: 5px;
            color: var(--color-primary);
            text-decoration: none;
          }
        }

        button {
          font-size: 14px;
          line-height: 16px;
          border: 0;
          color: #251f20;
          background-color: transparent;

          &:hover {
            padding-left: 5px;
            color: var(--color-primary);
            text-decoration: none;
          }
        }

        @media (--breakpoint-tablet-sm) {
          display: none;
        }
      }

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
        margin-bottom: 0;
      }
    }

    &__bottom {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #dbdbdb;
        left: 0;
        right: 0;
      }
      .copy {
        float: left;
        line-height: 24px;
      }

      .trademark {
        float: right;
        display: flex;
        align-items: center;

        .icon-svg {
          display: inline-block;
          margin-left: 10px;
          height: 24px;
          width: 80px;
          fill: #4b9cd7;
        }
      }
      .copyrights {
        padding: 15px;

        @media (--breakpoint-tablet-sm) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        @media (--breakpoint-desktop) {
          padding: 20px 0;
        }
      }
    }
  }
}
