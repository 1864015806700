#rebrand {
  .password-forget {
    position: relative;
    padding: 160px 0;

    &__title {
      margin-bottom: 18px;
      font-size: 24px;
      font-weight: 600;
      text-align: center;

      @media (--breakpoint-tablet-sm) {
        text-align: left;
      }
    }

    &__description {
      max-width: 348px;
      width: 100%;
      margin: 0 auto 25px;
      font-size: 14px;
      text-align: center;

      @media (--breakpoint-tablet-sm) {
        text-align: left;
      }
    }

    &__form {
      max-width: 491px;
      margin: 0 auto;

      &-button {
        button {
          width: 100%;
          height: 40px;
          margin-bottom: 20px;
          border-radius: 100px;
        }
      }

      .form-row {
        margin-bottom: 24px;
      }

      .field {
        input {
          height: 40px;
          padding: 12px 20px;
          border-radius: 100px;

          &::placeholder {
            font-size: 14px;
            color: var(--color-gray-dk);
          }
        }
      }
    }

    @media (--breakpoint-tablet-sm) {
      padding: 25px 0 160px;
    }
  }
}
