#rebrand {
  .product-categories {
    position: relative;
    padding-top: 35px;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -30px;
      background: linear-gradient(
        90deg,
        rgba(247, 247, 247, 0) 0%,
        #f7f7f7 100%
      );
      width: 10%;
      height: 100%;
      z-index: 10;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      gap: 18px;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        width: 7px;
        border-radius: 100px;
        background: var(--color-scrollbar-bg);
      }

      &::-webkit-scrollbar-thumb {
        width: 7px;
        border-radius: 100px;
        background: var(--redesign-color-border);
      }

      @media (--breakpoint-tablet-sm) {
        gap: 12px;
      }
    }

    &__item {
      min-width: 125px;
      max-width: 125px;
      width: 100%;
      margin-bottom: 24px;
      background-color: var(--color-white);
      border-radius: 10px;

      .box-categories {
        width: 100%;

        &:hover {
          img {
            transform: none;
          }
        }
      }

      .title {
        min-height: 60px;
        padding: 14px;
        margin-bottom: 0;
        font-size: 16px;
        color: var(--redesign-color-black-md);

        @media (--breakpoint-tablet-sm) {
          padding: 12px;
          font-size: 14px;
        }
      }

      figure {
        max-width: 170px;
        height: 105px;

        > img {
          width: 100%;
          height: 100%;
          border-radius: 0 0 10px 10px;
          object-fit: cover;
        }
      }

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 20px;
      }
    }

    @media (--breakpoint-tablet-sm) {
      padding-top: 25px;
      
      &::after {
        content: none;
      }
    }
  }
}
