#rebrand {
  .login {
    position: relative;
    padding: 35px 0 60px;

    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;
      gap: 100px;

      @media (--breakpoint-desktop) {
        grid-template-columns: 1fr;
      }
    }

    &__content {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background-color: var(--redesign-color-border);
      }

      @media (--breakpoint-desktop) {
        &::after {
          content: none;
        }
      }
    }

    &__title {
      margin-bottom: 25px;
      font-size: 24px;
      color: var(--redesign-color-black-md);
    }

    &__form {
      max-width: 491px;

      .form-row {
        margin-bottom: 24px;
      }

      .form-label {
        padding: 0;
      }

      .field {
        input {
          height: 40px;
          padding: 12px 20px;
          border-radius: 100px;

          &::placeholder {
            font-size: 14px;
            color: var(--color-gray-dk);
          }
        }
      }

      .password-reset {
        display: inline;
        padding: 24px 0;
        font-size: 12px;
        color: var(--redesign-color-black-md);
        text-align: left;
        text-transform: uppercase;
      }

      &-button {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        border-radius: 100px;
      }
    }

    &__divider {
      position: relative;
      margin-bottom: 24px;
      font-size: 14px;
      color: var(--color-gray-dk);
      text-align: center;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        max-width: 214px;
        width: 100%;
        height: 1px;
        background-color: var(--redesign-color-border);
        
        @media (--breakpoint-mobile) {
          max-width: 155px;
        }
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    &__socials {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .btn-socials-login {
      width: 100%;
      padding: 0;
      font-size: 14px;
      color: var(--redesign-color-black-md);
      background: transparent;
      border-radius: 100px;
      border: 1px solid var(--redesign-color-border);
      text-align: center;
      text-transform: none;

      > svg {
        margin-right: 8px;
      }
    }

    &__info {
      display: block;

      &-title {
        margin-bottom: 25px;
        font-size: 24px;
        color: var(--redesign-color-black-md);
      }

      &-list {
        margin-bottom: 38px;

        ul {
          list-style: none;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        p {
          font-size: 14px;
          color: var(--redesign-color-black-md);
        }
      }

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 491px;
        width: 100%;
        height: 40px;
        color: var(--redesign-color-primary);
        border-radius: 100px;
        border: 1px solid var(--redesign-color-primary);
        text-align: center;
        text-transform: uppercase;
      }

      @media (--breakpoint-tablet-sm) {
        display: none;
      }
    }

    @media (--breakpoint-tablet-sm) {
      padding: 25px 0 30px;
    }
  }
}
