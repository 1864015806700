.checkout-authentication {
    padding-bottom: 40px;
    
    &__option-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__banner {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        background-color: var(--color-white-md);
        border-radius: 5px;
        color: var(--color-black);
        font-size: 16px;
        line-height: 1.2em;
    }

    &__banner-btn {
        align-self: auto;
        
        @media(--breakpoint-tablet-up) {
            align-self: flex-start;
        }
    }

    &__option {
        padding: 19px 14px;
        border: 1px solid var(--redesign-color-border);
        border-radius: 5px;

        &:last-of-type {
            padding: 0;

            & > a {
                display: block;
                width: 100%;
                height: 100%;
                padding: 19px 14px;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__option-head {
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2em;
        color: var(--color-gray-dk);
    }
}