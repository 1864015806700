#rebrand {
  .profile-newsletter {
    &__wrapper {
      display: grid;
      grid-template-columns: 300px auto;
      column-gap: 30px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
      }
    }

    &__container {
      padding-top: 35px;

      @media (--breakpoint-tablet-sm) {
        padding: 30px 0;
      }
    }

    &__title {
      margin-bottom: 24px;
      font-size: 28px;
      font-weight: 600;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet-sm) {
        font-size: 24px;
      }
    }

    &__description {
      max-width: 361px;
      width: 100%;
      margin-bottom: 30px;
      font-size: 14px;
      color: var(--redesign-color-black-md);
    }

    &__form {
      &-subscribe,
      &-unsubscribe {
        max-width: 174px;
        width: 100%;
        padding: 12px 0;
        border-radius: 100px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;

        @media (--breakpoint-tablet-sm) {
          max-width: 100%;
        }
      }

      &-subscribe {
        color: var(--color-white);
        background-color: var(--redesign-color-primary);
      }

      &-unsubscribe {
        color: var(--redesign-color-primary);
        border: 1px solid var(--redesign-color-primary);
      }

      .field {
        margin-bottom: 30px;

        input {
          max-width: 474px;
          height: 40px;
          padding: 12px 20px;
          border-radius: 100px;
          border: 1px solid var(--redesign-color-border);

          &::placeholder {
            font-size: 14px;
            color: var(--redesign-color-black-md);
          }
        }
      }

      .form-label {
        display: block;
        padding-left: 12px;
        margin-bottom: 5px;
        color: var(--color-gray-dk);
        text-transform: none;
      }
    }
  }
}
