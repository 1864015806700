#rebrand {
  .accent-products-promo {
    position: relative;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin-bottom: 40px;

      h2 {
        font-size: 28px;
        color: var(--redesign-color-black-md);

        @media (--breakpoint-tablet-sm) {
          font-size: 24px;
          max-width: 94%;
        }
      }

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 25px;
      }
    }

    .wrapper {
      position: relative;

      @media (--breakpoint-tablet-sm) {
        right: 0;
      }
    }

    .swiper-wrapper {
      width: max-content;
    }

    .swiper-wrapper--fullwidth {
      width: 100%;
    }

    .boxes {
      @media (--breakpoint-tablet-sm) {
        margin-bottom: 30px;
      }
    }

    .box-basic {
      min-width: 310px;
      width: 100%;
      outline: none;
      border: 1px solid var(--redesign-color-border);
      border-radius: 10px;
      background: var(--color-white);

      @media (--breakpoint-tablet-sm) {
        min-width: 243px;
      }
    }

    .box-type-2 {
      padding: 19px 25px 0;

      @media (--breakpoint-tablet-sm) {
        padding: 12px 12px 0;

        .picture {
          min-height: 195px;
          max-height: 200px;
          height: 100%;
        }
      }
    }

    .ribbon {
      padding: 0;
      left: 10px;

      @media (--breakpoint-tablet-sm) {
        padding: 0;
        left: 0;
      }
    }

    .title {
      margin-bottom: 12px;
    }

    .box-price-wrap {
      border-color: var(--redesign-color-border);
    }

    .box-price-wrap {
      text-align: left;
    }

    .box-price {
      vertical-align: unset;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 5%;

      &::after {
        font-size: 20px;
        font-weight: 600;
      }

      @media (--breakpoint-tablet-sm) {
        top: 3%;

        &::after {
          font-size: 16px;
        }
      }
    }

    .swiper-button-prev {
      right: 50px;
      left: auto;
    }

    .brochure-button {
      padding: 12px 20px;
      font-size: 14px;
      color: var(--color-white);
      background-color: var(--redesign-color-primary);
      border: none;
      border-radius: 100px;
      text-transform: uppercase;

      @media (--breakpoint-tablet-sm) {
        width: 100%;
      }
    }

    .brochure-wrapper {
      &.mobile {
        display: none;
      }
      @media (--breakpoint-tablet-sm) {
        &.mobile {
          display: block;
          padding-right: 10px;
        }
        &.desktop {
          display: none;
        }
      }
    }
  }
}
