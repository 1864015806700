.shopping-cart-preview {
    &__activate-btn {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background-color: var(--color-white-md);
        color: var(--color-black);
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2em;
        z-index: 1;
    }

    &__container {
        position: relative;
        height: 0;
        visibility: hidden;
        background-color: var(--color-white-md);
        transform: translateY(-100%);
        transition: transform 0.5s ease-in-out;
        z-index: -1;
        pointer-events: none;
    }

    &__hidden-input:checked ~ &__container {
        height: auto;
        padding-bottom: 24px;
        transform: translateY(0);
        transition: transform 0.5s ease-in-out;
        z-index: 0;
        visibility: visible;
        pointer-events: all;
    }

    &__hidden-input:checked + &__activate-btn {
        .shopping-cart-preview__arrow {
            transform: rotate(0);
        }
    }

    &__count {
        color: var(--redesign-color-primary);
    }

    &__arrow {
        transform: rotate(180deg);
    }

    &__products-container {
        display: flex;
        flex-direction: column;
        gap: 16px;            
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--redesign-color-border);
    }

    &__hidden-input {
        display: none;
    }

    &__price, 
    &__delivery {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        color: var(--color-black);
        font-size: 16px;
        line-height: 1.2em;
        padding-bottom: 4px;
        
        &:last-of-type {
            padding-bottom: 12px;
            border-bottom: 1px solid var(--redesign-color-border);
        }

        span {
            font-weight: 700;
            font-size: 18px;
        }
    }

    &__delivery {
        padding: 12px 0;
        border-bottom: 1px solid var(--redesign-color-border);
    }

    &__total {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        color: var(--color-black);
        font-weight: 700;
        font-size: 18px;
        line-height: 1.2em;

        span {
            font-size: 20px;
            line-height: 1.2em;
        }
    }

    &__warning {
        display: flex;
        gap: 8px;

        svg {
            min-width: 16px;
            min-height: 16px;
            display: block;
        }
    }
    
    &__optional-field {
        padding: 16px 0;
        border-bottom: 1px solid var(--redesign-color-border);

        .shopping-cart-preview__optional-row {
            display: flex;
            flex-wrap: wrap;
        }

        .shopping-cart-preview__wraped-text {
            display: block;
            width: 100%; 
            color: var(--color-text);
            font-size: 14px;
        }

        .shopping-cart-preview__no-wrap {
            white-space: nowrap;
        }
    }

    &--mobile {
        @media(--breakpoint-tablet-up) {
            display: none;
        }
    }
}

.shopping-cart-preview--desktop .shopping-cart-preview__container {
    display: flex;
}

.shopping-cart-preview--desktop {
    display: none;
    
    @media(--breakpoint-tablet-up) {
        display: block;
        align-self: flex-start;
        padding: 20px;
        margin-bottom: 20px;
        background-color: var(--color-white-md);
        border-radius: 4px;
    }
}