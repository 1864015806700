#rebrand {
  .password-change {
    &__wrapper {
      display: grid;
      grid-template-columns: 300px auto;
      column-gap: 30px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
      }
    }

    &__container {
      padding-top: 35px;
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 10px;
      font-size: 14px;
      color: var(--color-gray-dk);
    }

    &__title {
      margin-bottom: 24px;
      font-size: 28px;
      font-weight: 600;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet-sm) {
        font-size: 24px;
      }
    }

    &__form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      .field {
        input {
          height: 40px;
          padding: 12px 20px;
          border-radius: 100px;
          border: 1px solid var(--redesign-color-border);

          &::placeholder {
            font-size: 14px;
            color: var(--color-gray-dk);
          }
        }
      }

      .form-label {
        display: block;
        padding-left: 12px;
        margin-bottom: 5px;
        color: var(--color-gray-dk);
        text-transform: none;
      }

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
      }
    }

    &__button {
      grid-column: span 2;

      button {
        max-width: 174px;
        width: 100%;
        padding: 12px 0;
        color: var(--color-white);
        border-radius: 100px;
        background-color: var(--redesign-color-primary);
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;

        @media (--breakpoint-tablet-sm) {
          max-width: 100%;
        }
      }

      @media (--breakpoint-tablet-sm) {
        grid-column: unset;
      }
    }
  }
}
