.news-list {
    &__container {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: 16px;

        @media(--breakpoint-mobile-up) {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }

        @media(--breakpoint-tablet-sm-up) {
            grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
            gap: 20px;
        }
    }

    &__btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0;

        @media(--breakpoint-tablet-sm-up) {
            padding: 50px 0;
        }
    }
}

#rebrand .news-list__btn-container .btn {
    @media(--breakpoint-tablet-sm-up) {
        width: unset;
    }
}

.news-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 327px;
    height: 100%;

    &__content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        padding: 16px;
        background-color: var(--color-white-md);
        color: var(--color-black);
        border-radius: 0 0 10px 10px;

        @media(--breakpoint-tablet-sm-up) {
            border-radius: 10px 0 0 10px;
        }
    }

    &__image {
        width: 100%;
        max-height: 150px;
        object-fit: cover;
        object-position: center;
        border-radius: 10px 10px 0 0;

        @media(--breakpoint-tablet-sm-up) {
            max-width: 200px;
            min-height: 100%;
            border-radius: 0 10px 10px 0;
        }
    }

    &__head {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2em;
        padding-bottom: 6px;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 5px;
        text-transform: uppercase;
        color: var(--redesign-color-primary);
    }
    
    @media(--breakpoint-tablet-sm-up) {
        flex-direction: row-reverse;
        max-width: 420px;
        height: 100%;
    }
}

/*! autoprefixer: off */
.news-item__text {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    font-size: 14px;
    line-height: 1.2em;
    max-height: calc(3 * 1.2em);
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-bottom: 30px;
    
    @media(--breakpoint-tablet-sm-up) {
        line-clamp: 5;
        -webkit-line-clamp: 5;
        max-height: calc(5 * 1.2em);
        margin-bottom: unset;
    }
}
/*! autoprefixer: on */