#rebrand {
  .product-info {
    &__list {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: start;
      margin-bottom: 50px;

      &.active {
        .product-info__item {
          display: block;
        }

        .product-info__button::after {
          transform: translateY(-50%);
          transition: transform 0.3s ease-out;
        }
      }

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
        margin-bottom: 30px;
      }

      @media (--breakpoint-tablet-sm-up) and (--breakpoint-tablet) {
        grid-template-columns: 328px auto;
        gap: 23px;
      }

      @media (--breakpoint-tablet-up) and (--breakpoint-desktop) {
        grid-template-columns: 518px auto;
        gap: 23px;
      }
    }

    &__button {
      position: relative;
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: var(--redesign-color-black-md);
      background-color: transparent;
      border: 0;
      text-align: left;
      text-transform: uppercase;

      @media (--breakpoint-tablet-sm) {
        width: 100%;
        margin-bottom: 16px;
        cursor: pointer;

        &::after {
          content: url("../../images/arrow.svg");
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%) rotate(180deg);
          transition: transform 0.3s ease-in;
        }
      }
    }

    &__item {
      display: block;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet-sm) {
        display: none;
      }
    }

    &__description {
      margin-bottom: 12px;
      font-size: 14px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__features {
      list-style: none;

      &-item {
        position: relative;
        padding-left: 10px;

        span {
          display: block;
          padding-left: 10px;
          font-size: 14px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background-color: var(--redesign-color-black-md);
        }
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: "title link" "address link";
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid var(--redesign-color-border);

      &:first-of-type {
        padding-top: 0;
      }

      &-title {
        grid-area: title;
        font-size: 16px;
      }

      &-address {
        grid-area: address;

        @media (--breakpoint-tablet-sm) {
          max-width: 276px;
        }
      }

      &-link {
        grid-area: link;
        justify-self: end;

        svg {
          fill: var(--color-gray-dk);

          &:hover {
            fill: var(--redesign-color-primary);
          }
        }
      }
    }

    .table-basic tr:nth-of-type(odd) {
      background-color: var(--color-white-md);
    }

    .table-basic td {
      border: 0;
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }

    .table-basic tr:hover td {
      background-color: transparent;
    }

    .box-type-6 {
      margin: 0;
    }
  }
}
