#rebrand {
  .product-order-item {
    &__wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      @media (--breakpoint-tablet-sm) {
        gap: 0;
      }
    }

    &__image {
      max-width: 156px;
      width: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid var(--redesign-color-border);
        object-fit: cover;

        @media (--breakpoint-tablet-sm) {
          height: 58px;
          border: 0;
        }
      }

      @media (--breakpoint-tablet-sm) {
        max-width: 73px;
      }
    }

    &__content {
      @media (--breakpoint-tablet-sm) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "title title" "list price";
        padding: 12px;
        border-left: 1px solid var(--redesign-color-border);
      }
    }

    &__title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 600;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet-sm) {
        grid-area: title;
        font-size: 14px;
      }
    }

    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 12px;

      &-item {
        p,
        span {
          font-size: 14px;
          font-weight: 400;

          @media (--breakpoint-tablet-sm) {
            font-size: 12px;
          }
        }

        p {
          padding: 0 5px;
          color: #77797c;
          border-right: 1px solid var(--redesign-color-border);

          @media (--breakpoint-tablet-sm) {
            border-right: 0;
          }
        }

        span {
          color: var(--redesign-color-black-md);
        }
      }

      @media (--breakpoint-tablet-sm) {
        grid-area: list;
        flex-direction: column;
        align-items: start;
        margin-bottom: 0;
      }
    }

    &__price {
      p {
        font-size: 18px;
        font-weight: 700;
        color: var(--redesign-color-black-md);
      }

      span {
        font-size: 14px;
      }
      @media (--breakpoint-tablet-sm) {
        grid-area: price;
        margin-top: auto;
      }
    }

    .color {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .color-box {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid;

      @media (--breakpoint-tablet-sm) {
        width: 14px;
        height: 14px;
      }
    }

    @media (--breakpoint-tablet-sm) {
      border-radius: 10px;
      background-color: var(--color-white);
    }
  }
}
