#rebrand {
  .sorting {
    display: block;

    .field-select::before {
      content: url("../../images/sort-icon.svg");
      width: 13px;
      height: 17px;
      left: 20px;
      margin-top: 0;
      transform: translateY(-50%);
      border-width: 0;
      border-style: none;
    }

    .sorting-select {
      position: relative;
      padding: 9px 20px;
      font-size: 14px;
      border: 1px solid var(--redesign-color-border);
      border-radius: 100px;
      cursor: pointer;

      &.open {
        border: 1px solid var(--redesign-color-primary);

        .sorting-select__options {
          display: flex;
          flex-direction: column;
          padding: 8px 0;
        }
      }

      &__trigger {
        display: block;
        padding-left: 19px;
        color: var(--redesign-color-black-md);
        text-transform: uppercase;
      }

      &__options {
        display: none;
        position: absolute;
        width: 270px;
        top: 110%;
        right: 0;
        border: 1px solid var(--redesign-color-border);
        background-color: var(--color-white);
        border-radius: 8px;
        z-index: 10;
      }

      &__option {
        padding: 10px;
        transition: background-color 0.2s ease;
        cursor: pointer;

        &:hover {
          background-color: var(--color-white-md);
        }
      }
    }

    &__modal {
      display: none;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);

      &-content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 40%;
        padding-top: 30px;
        background-color: var(--color-white);
        border-radius: 15px 15px 0 0;
      }

      &-close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 13px;
        height: 13px;
        cursor: pointer;
      }

      &-title {
        margin-bottom: 24px;
        font-size: 18px;
        text-align: center;
      }

      &-options {
        padding: 0 16px;
        list-style: none;

        li {
          position: relative;
          padding: 16px 0;
          border-bottom: 1px solid var(--redesign-color-border);
          cursor: pointer;

          &.selected::after {
            content: url("../../images/check-dark.svg");
            position: absolute;
            width: 14px;
            height: 9px;
            right: 16px;
          }

          &:first-of-type {
            padding-top: 0;
          }
        }
      }

      @media (--breakpoint-desktop) {
        display: none;
      }
    }

    @media (--breakpoint-desktop) {
      display: none;
    }
  }
}
