#rebrand .menu-desktop-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    font-size: 16px;
    line-height: 1.5em;
    cursor: pointer;

    &:hover {
        background-color: var(--redesign-color-primary);
    }

    &__arrow-selected {
        display: none;
    }

    &__expand {
        position: absolute;
        top: 0;
        left: calc(100% + 1px);
        display: none;
        flex-direction: column;
        justify-content: space-between;
        max-height: calc(100vh - (var(--subhead-height, 64px) + var(--header-height, 40px)));
        height: 100%;
        min-width: 300px;
        background-color: var(--color-white);
        border-right: 1px solid var(--redesign-color-border);

        &--active {
            display: flex;
        }
    }

    &__container {
        flex: 1;
        max-height: calc(100% - var(--menu-desktop-bottom-btn-height, 53px));
    }

    &__title {
        box-sizing: border-box;
        height: 100%;
        max-height: var(--menu-desktop-list-head-height, 77px);
        padding: 25px 20px;
        border-bottom: 1px solid var(--redesign-color-border);
    }

    &__list {
        max-height: calc(100% - var(--menu-desktop-list-head-height, 77px));
        overflow: auto;
    }

    &__link {
        max-width: 240px;
    }

    &__bottom-btn {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--menu-desktop-bottom-btn-height, 53px);
        padding: 14px 0;
        color: var(--color-black);
        border-top: 1px solid var(--redesign-color-border);
    }

    &__preview {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        max-height: calc(100vh - (var(--subhead-height, 64px) + var(--header-height, 40px)));
        height: 100%;
        padding: 16px;
        background-color: var(--color-white);
        border-right: 1px solid var(--redesign-color-border);
        border-left: 1px solid var(--redesign-color-border);

        &--active {
            @media(min-width: 1220px) {
                display: block;
            }
        }
    }

    &__preview-img {
        object-fit: cover;
        min-width: 360px;
        height: 100%;
        max-height: 640px;
        border-radius: 5px;
    }
}

#rebrand .menu-desktop-option>.menu-desktop-option__link {
    color: var(--color-black);
}

#rebrand .menu-desktop-option:hover>.menu-desktop-option__link {
    color: var(--color-white);
}

#rebrand .menu-desktop-option:hover>.menu-desktop-option__arrow {
    display: none;
}

#rebrand .menu-desktop-option:hover>.menu-desktop-option__arrow-selected {
    display: block;
}

#rebrand .menu-desktop {
    --subhead-height: 64px;
    --header-height: 40px;
    --menu-desktop-list-head-height: 77px;
    --menu-desktop-bottom-btn-height: 53px;

    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    height: calc(100vh - (var(--subhead-height, 64px) + var(--header-height, 40px)));
    min-width: 300px;
    background-color: var(--color-white);
    border-top: 1px solid var(--redesign-color-border);
    border-right: 1px solid var(--redesign-color-border);

    &__head {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-height: var(--menu-desktop-list-head-height, 77px);
        padding: 25px 20px;
        border-bottom: 1px solid var(--redesign-color-border);
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5em;
    }

    &__close-btn {
        min-width: 30px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }

    &__list {
        max-height: calc(100% - var(--menu-desktop-list-head-height, 77px));
        overflow: auto;
    }

    a {
        transition: none;
        user-select: none;
    }
}

#rebrand.menu-open .menu-desktop {
    display: block;
}

/* When the screen is not big enough for level 4 the arrows on the links in level 3 will be hidden */
.menu-desktop-option__expand[data-level="3"] .menu-desktop-option > .menu-desktop-option__arrow {
    display: none;

    @media(--breakpoint-desktop-xl-up) {
        display: block;
    }
}

.menu-desktop-option__expand[data-level="3"] .menu-desktop-option:hover > .menu-desktop-option__arrow {
    display: none;
}

.menu-desktop-option__expand[data-level="3"] .menu-desktop-option > .menu-desktop-option__arrow-selected {
    visibility: hidden;
}

.menu-desktop-option__expand[data-level="3"] .menu-desktop-option:hover > .menu-desktop-option__arrow-selected {
    visibility: hidden;

    @media(--breakpoint-desktop-xl-up) {
        visibility: visible;
    }
}