#rebrand {
  .faq {
    position: relative;
    margin-bottom: 80px;

    &__title {
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: 600;
      color: #251f20;

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 25px;
        font-size: 24px;
      }
    }

    &__item {
      padding: 16px 0;
      border-bottom: 1px solid #dbdbdb;

      &:first-of-type {
        padding-top: 0;
      }

      &.active {
        .faq__item-info {
          display: block;
        }

        .faq__item-button {
          margin-bottom: 16px;
        }

        .faq__item-arrow {
          transform: none;
          transition: transform 0.3s ease-out;
        }
      }

      &-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      &-list {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #251f20;
        text-align: left;

        @media (--breakpoint-tablet-sm) {
          max-width: 300px;
        }
      }

      &-arrow {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in;
      }

      &-info {
        display: none;
      }

      &-content {
        font-size: 14px;
        color: #251f20;
      }
    }

    @media (--breakpoint-tablet-sm) {
      margin-bottom: 50px;
    }
  }
}
