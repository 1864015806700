#rebrand {
  .filters-aside {
    &__wrapper {
      @media (--breakpoint-desktop) {
        overflow: auto;
      }
    }

    .filters-aside-body {
      &__button {
        display: none;

        button {
          width: 100%;
          padding: 12px 0;
          font-size: 14px;
          letter-spacing: 0.42px;
          color: var(--color-white);
          background: var(--redesign-color-primary);
          border: 0;
          border-radius: 100px;
          text-transform: uppercase;
          cursor: pointer;
        }

        @media (--breakpoint-desktop) {
          display: block;
          margin-top: auto;
          padding: 30px 16px;
          border-top: 1px solid var(--redesign-color-border);
        }
      }

      &__container {
        @media (--breakpoint-desktop) {
          position: absolute;
          width: 100%;
          height: 95%;
          bottom: 0;
          left: 0;
          background-color: var(--color-white);
          border-radius: 15px 15px 0 0;
        }
      }

      @media (--breakpoint-desktop) {
        display: block;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.5);
        overflow: hidden;
      }
    }

    .filters-aside-head {
      &::before,
      &::after {
        content: none;
      }

      @media (--breakpoint-desktop) {
        margin: 0 0 24px;
        padding: 30px 16px 0;
        border: 0;

        .title {
          margin: 0 auto;
          padding: 0;
          font-size: 18px;
          color: var(--redesign-color-black-md);
          text-align: center;
        }
      }
    }

    .filters-aside-item {
      @media (--breakpoint-desktop) {
        padding: 0;
        margin: 0 16px;

        &.is-active {
          padding: 0 0 20px;

          .filters-aside-item-head {
            margin: 0;
          }
        }

        .filters-aside-item-head {
          padding: 0;
          .title {
            padding: 16px 0 0;
          }
        }
      }
    }

    .filters-close {
      display: none;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 13px;
      height: 13px;
      cursor: pointer;

      .icon-svg {
        width: 13px;
        height: 13px;
        padding: 0;
      }

      @media (--breakpoint-desktop) {
        display: block;
      }
    }

    &__btns {
      display: none;
      position: absolute;
      top: 35px;
      left: 16px;

      button {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.42px;
        color: var(--redesign-color-primary);
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
      }

      @media (--breakpoint-desktop) {
        display: block;
      }
    }
  }
}
