#rebrand {
  .categories-sidebar-nav {
    .hidden {
      display: none;
    }
    .visible {
      display: block;
    }

    &__title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
      color: var(--redesign-color-black-md);
      text-transform: uppercase;

      @media (--breakpoint-desktop) {
        display: none;
      }
    }

    &__body {
      .close {
        display: none;
      }

      @media (--breakpoint-desktop) {
        padding: 20px 16px;

        .close {
          display: block;
          position: absolute;
          top: 20px;
          right: 16px;
          width: 13px;
          height: 13px;
          cursor: pointer;
        }
      }
    }

    &__filters {
      .category,
      .subcategory {
        font-size: 16px;
        line-height: 1.75;
        vertical-align: middle;
        cursor: pointer;

        a {
          color: var(--color-gray-dk);
        }
      }

      .subcategories {
        &__name {
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 6px;
          padding: 10px;
          margin-bottom: 18px;
          border: 1px solid var(--redesign-color-border);
          border-radius: 3px;

          a {
            display: flex;
            gap: 8px;
            font-weight: 400;
            background-color: transparent;
            border: 0;
            color: var(--color-gray-dk);
            cursor: pointer;

            &::before {
              content: url("../../images/back-btn.svg");
              display: inline-block;
              width: 11px;
              height: 11px;
            }
          }

          span {
            display: block;
            font-size: 16px;
            font-weight: 600;
            color: var(--redesign-color-black-md);
          }

          @media (--breakpoint-desktop) {
            gap: 16px;
            padding: 0 0 18px;
            border: 0;
            border-bottom: 1px solid var(--redesign-color-border);
          }
        }
      }
    }

    &__head {
      font-size: 18px;
      line-height: 1.2em;
      text-align: center;
      color: var(--color-black);

      @media(--breakpoint-desktop-up) {
        display: none;
      }
    }

    @media (--breakpoint-desktop) {
      position: absolute;
      width: 100%;
      height: 80%;
      bottom: 0;
      left: 0;
      background-color: var(--color-white);
      border-radius: 15px 15px 0 0;
    }
  }
}
