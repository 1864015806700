#rebrand {
  .cart-edit-modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: none;
    background-color: rgba(0, 0, 0, 0.5);
    transform: none;
    overflow: hidden;
    z-index: 1000;

    &.active {
      display: block;
    }

    &__close {
      display: flex;
      justify-content: end;
      padding-right: 16px;
      cursor: pointer;
    }

    &__container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 30px 0;
      border-radius: 15px 15px 0 0;
      background-color: var(--color-white);
    }

    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 600;
      color: var(--redesign-color-black-md);
      text-align: center;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 0 16px;
    }

    &__button {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      color: var(--redesign-color-black-md);
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:first-of-type {
        padding-bottom: 16px;
        border-bottom: 1px solid var(--redesign-color-border);
      }

      &:last-of-type {
        padding-top: 16px;
      }
    }
  }
}
