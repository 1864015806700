.delivery-option-slider {
    &__slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 146px;
        width: 100%;
        min-height: 117px;
        height: 100%;
        padding-bottom: 14px;
        border: 1px solid var(--redesign-color-border);
        border-radius: 4px;
        cursor: pointer;

        @media(--breakpoint-tablet-up) {
            height: 118px;
        }
    }

    &__option {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 8px 12px;
        margin-bottom: 14px;
        border-bottom: 1px solid var(--redesign-color-border);
        color: var(--color-gray-dk);
        font-weight: 600;
        font-size: 14px;
        line-height: 1.2em;
        white-space: nowrap;
        
        @media(--breakpoint-tablet-up) {
            font-size: 16px;
        }
    }

    &__hidden-input {
        display: none;
    }
}

.delivery-options-container {
    margin-right: calc(-1 * var(--wrapper-padding));

    @media(--breakpoint-mobile-up) {
        margin-right: unset;
    }
}

.delivery-option-slider__wrapper .delivery-option-slider__slide.selected {
    border-color: var(--redesign-color-primary);

    .delivery-option-slider__option {
        color: var(--color-black);
        border-color: var(--redesign-color-primary);
    }
}

.delivery-option-slider__slide:hover {
    border-color: var(--color-border-lt);

    .delivery-option-slider__option {
        border-color: var(--color-border-lt);
    }
}

#rebrand .swiper-wrapper.delivery-option-slider__wrapper {
    @media(--breakpoint-mobile-up) {
        display: flex;
        gap: 14px;
    }
}