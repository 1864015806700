#rebrand {
  .accent-special-offers {
    position: relative;
    padding: 0;
    margin-bottom: 80px;

    &__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
        gap: 13px;
      }
    }

    @media (--breakpoint-tablet-sm) {
      margin-bottom: 50px;
    }
  }
}
