.register-modal {
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 17px;
        font-size: 24px;
        line-height: 1.2em;
        font-weight: 600;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__consent {
        display: flex;
        gap: 12px;

        a {
            color: var(--color-black);
            text-decoration: underline;
        }
    }
}