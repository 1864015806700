#rebrand {
  .box-basic {
    min-width: 310px;
    width: 100%;
    outline: none;
    border: 1px solid var(--redesign-color-border);
    border-radius: 10px;

    @media (--breakpoint-tablet-sm) {
      min-width: 243px;
    }
  }

  .box-type-2 {
    padding: 19px 12px 0;

    @media (--breakpoint-tablet-sm) {
      padding: 12px 12px 0;
    }
  }

  .ribbon {
    padding: 0;
    left: 10px;

    @media (--breakpoint-tablet-sm) {
      padding: 0;
      left: 0;
    }
  }

  .title {
    margin-bottom: 12px;
  }

  .box-price-wrap {
    border-color: var(--redesign-color-border);
  }

  .box-price-wrap {
    text-align: left;
  }

  .box-price {
    vertical-align: unset;
  }
}
