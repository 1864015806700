.enquiry-modal {
    display: flex;
    flex-direction: column;

    &__head {
        display: flex;
        justify-content: space-between;
        color: var(--redesign-color-black-md);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2em;
    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 25px;
    }

    &__text {
        font-size: 16px;
        line-height: 1.2em;

        a {
            color: var(--redesign-color-black-md);
            text-decoration: underline;
        }
    }

    &__checkbox {
        display: flex;
        gap: 12px;
    }
}