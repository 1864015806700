#rebrand {
  .profile-personal {
    position: relative;
    padding-top: 30px;

    &__title {
      margin-bottom: 35px;
      font-size: 28px;
      font-weight: 600;
      color: var(--redesign-color-black-md);

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__form {
      margin-bottom: 35px;
      border-bottom: 1px solid var(--redesign-color-border);
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "title button"
        "form form";
      row-gap: 20px;

      &-title {
        grid-area: title;
        font-size: 20px;
        font-weight: 600;

        @media (--breakpoint-tablet-sm) {
          margin-bottom: 16px;
        }
      }

      &-button {
        grid-area: button;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 9px 20px;
        border-radius: 100px;
        color: var(--redesign-color-primary);
        background-color: var(--redesign-color-white-md);
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      form {
        grid-area: form;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @media (--breakpoint-tablet-sm) {
          grid-template-columns: 1fr;
          margin-bottom: 30px;
        }
      }

      .field {
        input {
          height: 40px;
          padding: 12px 20px;
          border-radius: 100px;
          border: 0;
          background-color: var(--color-white-md);

          &::placeholder {
            font-size: 14px;
            color: var(--redesign-color-black-md);
          }
        }
      }

      .form-label {
        padding-left: 12px;
        color: var(--color-gray-dk);
        text-transform: none;
      }

      .form-row {
        padding-bottom: 0;
      }

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
        grid-template-areas: "title" "form" "button";
        row-gap: 0;
        margin-bottom: 30px;
      }
    }

    &__button {
      button {
        max-width: 204px;
        width: 100%;
        padding: 9px 24px;
        font-size: 14px;
        font-weight: 700;
        color: var(--redesign-color-red);
        border-radius: 100px;
        border: 1px solid var(--redesign-color-red);
        background: transparent;
        text-transform: uppercase;

        @media (--breakpoint-tablet-sm) {
          max-width: 100%;
        }
      }
    }

    &__address {
      margin-bottom: 35px;
      border-bottom: 1px solid var(--redesign-color-border);

      &-head {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        @media (--breakpoint-tablet-sm) {
          grid-template-columns: 1fr;
          gap: 30px;
        }
      }
      
      &-title {
        font-size: 20px;
        font-weight: 600;
      }
      
      &-list {
        padding-top: 25px;
      }

      &-container {
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding: 20px;
        margin-bottom: 30px;
        border-radius: 10px;
        background-color: var(--color-white-md);

        .title {
          margin-bottom: 16px;
          font-size: 18px;
          font-weight: 600;
          color: var(--redesign-color-black-md);
        }

        .btn {
          max-width: 149px;
          width: 100%;
          background-color: var(--color-white);

          @media (--breakpoint-tablet-sm) {
            max-width: 100%;
          }
        }

        @media (--breakpoint-tablet-sm) {
          flex-direction: column;
          gap: 25px;
        }
      }
    }

    .btn-add {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 9px 20px;
      color: var(--color-white);
      border-radius: 100px;
      background-color: var(--redesign-color-primary);
      font-size: 14px;
      text-transform: uppercase;
    }

    .addresses-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      max-width: 164px;
      width: 100%;
      padding: 9px 0;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-white);
      border-radius: 100px;
      background-color: var(--redesign-color-primary);
      text-transform: uppercase;

      @media (--breakpoint-tablet-sm) {
        max-width: 100%;
      }
    }
  }
}
