#rebrand {
  .product-counter {
    position: relative;
    max-width: 100%;
    border: 1px solid var(--redesign-color-border);
    border-radius: 100px;

    &--secondary {
      .field-number {
        width: 160px;

        > input {
          height: 30px;
        }
      }
    }

    .field-number {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 5px;

      > input {
        border: 0;
        padding: 0;
        text-align: right;
      }

      > button {
        position: absolute;
        background-color: transparent;
        border: 0;
        cursor: pointer;
      }

      .postfix {
        position: relative;
        top: unset;
        right: unset;
        transform: none;
        font-size: 12px;
        line-height: 13px;
        color: var(--color-gray-dk);
        text-transform: uppercase;
      }

      @media (--breakpoint-tablet-sm) {
        width: 100%;
      }
    }

    .counter-minus {
      top: 50%;
      transform: translateX(-50%);
      left: 20px;
    }

    .counter-plus {
      top: 30%;
      transform: translateX(-35%);
      right: 10px;
    }

    .counter-container {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
      gap: 17px;
      margin-bottom: 30px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
        gap: 0;
        margin-bottom: 12px;
      }
    }

    @media (--breakpoint-tablet-sm) {
      max-width: 100%;
    }
  }
}
