.parallax-container {
	position: relative;
	overflow: hidden;
	height: 500px;
}

.parallax-container .parallax {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.parallax-container .parallax img {
	display: none;
	position: absolute;
	left: 50%;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}