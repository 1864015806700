.video-slider {
    margin-right: calc(-1 * var(--wrapper-padding));

    &__slide {
        max-width: 284px;
        height: 189px;
        border-radius: 10px;

        @media(--breakpoint-tablet-sm-up) {
            max-width: unset;
            min-width: 420px;
            height: 280px;
        }
    }

    @media(--breakpoint-tablet-sm-up) {
        margin-right: unset;
    }
}

.video-slider.swiper-container.inactive .video-slider__wrapper {
    display: flex;
    gap: 20px;
}