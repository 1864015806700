#rebrand {
  .cart-edit-count {
    display: none;
    z-index: 1000;

    &.active {
      display: block;
    }

    &__close {
      display: flex;
      justify-content: end;
      padding-right: 16px;
      cursor: pointer;
    }

    &__container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 30px 0;
      border-radius: 15px 15px 0 0;
      background-color: #fff;
    }

    &__title {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 600;
      color: #251f20;
      text-align: center;
    }

    &__wrapper {
      padding: 0 16px;
    }

    &__content {
      margin-bottom: 20px;
    }

    &__price {
      font-weight: 700;
      line-height: 20px;
      color: #251f20;

      p {
        font-size: 14px;
      }

      span {
        font-size: 18px;
      }
    }

    .field-number {
      input {
        height: 40px;
      }
    }

    &__button {
      width: 100%;
      padding: 12px 0;
      border-radius: 100px;
      background-color: #99cf28;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.42px;
      text-transform: uppercase;
    }

    &__element {
        display: flex;
        justify-content: center;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 9px 0;
      margin-bottom: 20px;
      background-color: #f7f7f7;

      span {
        display: block;
        font-size: 12px;
        color: #77797c;
      }
    }
  }
}
