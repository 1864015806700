#rebrand {
  .cart-item {
    position: relative;
    max-width: 861px;
    width: 100%;
    border-radius: 4px;
    background-color: var(--color-white);

    &.hidden {
      display: none;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 150px auto;
      gap: 25px;
      padding: 20px;

      &.counters {
        .cart-item__body {
          display: grid;
          grid-template-columns: 2fr 1fr;
          grid-template-areas:
            "variations variations"
            "counter counter"
            "calc ."
            "info price";
          gap: 8px;
          align-items: center;

          @media (--breakpoint-tablet-sm) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
              "variations variations"
              "counter price";
            gap: 0;
            align-items: end;
            margin-top: auto;
          }
        }
      }

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 100px auto;
        grid-template-rows: auto auto;
        grid-template-areas: "elements content" "elements content";
        padding: 12px 16px;
        gap: 0;
      }
    }

    &__elements {
      @media (--breakpoint-tablet-sm) {
        grid-area: elements;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-right: 16px;

        &::after {
          content: "";
          position: absolute;
          top: -12px;
          right: 0;
          width: 1px;
          height: calc(100% + 24px);
          background-color: var(--redesign-color-border);
        }
      }
    }

    &__image {
      max-width: 150px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        object-fit: cover;
      }

      @media (--breakpoint-tablet-sm) {
        max-width: 75px;
        padding-bottom: 12px;
      }
    }

    &__button {
      display: none;

      @media (--breakpoint-tablet-sm) {
        display: block;
        position: relative;
        margin-top: auto;
        padding-top: 10px;
        font-size: 12px;
        font-weight: 700;
        color: var(--redesign-color-black-md);
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -16px;
          width: calc(100% + 32px);
          height: 1px;
          background-color: var(--redesign-color-border);
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;

      @media (--breakpoint-tablet-sm) {
        grid-area: content;
        gap: 0;
        padding-left: 16px;
      }
    }

    &__body {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-areas:
        "variations variations"
        "counter price";
      align-items: end;

      &-variations {
        grid-area: variations;
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 4px;

        @media (--breakpoint-tablet-sm) {
          flex-direction: column;
          align-items: start;
          gap: 0;
          margin-bottom: 0;
        }
      }

      &-container {
        grid-area: counter;
        display: flex;
        justify-content: space-between;
        align-items: end;
      }

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      align-items: end;
      gap: 20px;

      @media (--breakpoint-tablet-sm) {
        flex-direction: column-reverse;
        align-items: start;
        gap: 0;
      }
    }

    &__item {
      &-price {
        font-size: 14px;
        font-weight: 700;
        color: var(--redesign-color-black-md);

        span {
          font-size: 18px;
        }

        @media (--breakpoint-tablet-sm) {
          display: none;
        }
      }

      &-count {
        display: none;

        @media (--breakpoint-tablet-sm) {
          display: block;
        }
      }

      p {
        font-size: 16px;
        color: var(--color-black);

        @media (--breakpoint-tablet-sm) {
          font-size: 12px;
        }
      }

      span {
        font-size: 14px;
        color: var(--redesign-color-black-md);
      }

      @media (--breakpoint-tablet-sm) {
        .product-counter {
          display: none;
        }

        &-detail {
          display: none;
        }
      }
    }

    &__price {
      grid-area: price;
      font-size: 18px;
      font-weight: 700;
      color: var(--redesign-color-black-md);
      text-align: right;

      span,
      small {
        font-size: 14px;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-button {
        max-width: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        @media (--breakpoint-tablet-sm) {
          display: none;
        }
      }

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 8px;
      }
    }

    &__title {
      max-width: 467px;
      width: 100%;
      font-size: 14px;
      line-height: normal;
      color: var(--redesign-color-black-md);
      text-align: left;
      text-transform: uppercase;
    }

    &__price {
      &-info {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--redesign-color-black-md);

        span,
        small {
          font-size: 20px;
        }

        @media (--breakpoint-tablet-sm) {
          font-size: 12px;

          span {
            font-size: 16px;
          }
        }
      }
    }

    .product-calc-utils {
      grid-area: calc;
      padding: 7px 0;
      font-size: 12px;
      color: var(--color-gray-dk);
      background-color: var(--color-white-md);
      text-align: center;

      @media (--breakpoint-tablet-sm) {
        display: none;
      }
    }

    .product-calc-info {
      grid-area: info;
      font-size: 12px;
      color: var(--color-gray-dk);

      @media (--breakpoint-tablet-sm) {
        display: none;
      }
    }

    .color {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .color-box {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid;

      @media (--breakpoint-tablet-sm) {
        width: 14px;
        height: 14px;
      }
    }
  }
}
