
.redesign-field-container {
    width: 100%;
}

.redesign-field {
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    border: 1px solid var(--redesign-color-border);

    &:focus {
        border-color: var(--color-black);
    }
}

.redesign-field-input {
    height: 40px;
    border-radius: 100px;
}

.redesign-field-textarea {
    padding-top: 12px;
    border-radius: 10px;

    @media(--breakpoint-tablet-sm-up) {
        min-height: 120px;
    }
}

.redesign-field-textarea--smaller {
    min-height: 60px; 

    @media(--breakpoint-tablet-sm-up) {
        min-height: 60px;
    }   
}

.redesign-field-error {
    display: none;
    padding-left: 12px;
    padding-top: 5px;
    color: var(--error);
}

.redesign-field-container--error .redesign-field {
    border-color: var(--error);

    &::placeholder {
        color: var(--error);
    }
}

.redesign-field-container--error .redesign-field-error {
    display: block;
}