.expandable-option {
    &__head {
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2em;
        color: var(--color-gray-dk);
    }
    
    &__container {
        display: none;
    }
    
    &__trigger:checked ~ &__head {
        padding-bottom: 16px;
        color: var(--color-black);

        @media(--breakpoint-tablet-sm-up) {
            padding-bottom: 20px;
        }
    }

    &__trigger:checked ~ &__container {
        display: block;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 15px;

        @media(--breakpoint-tablet-sm-up) {
            flex-direction: row;
            gap: 12px;
        }
    }

    &__separator {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 30px 0;
        color: var(--color-gray-dk);
        text-align: center;
        font-size: 16px;
        line-height: 1.2em;
    }

    &__separator::before,
    &__separator::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid var(--redesign-color-border);
        margin: 0 12px;
    }

    &__link {
        font-size: 12px;
        line-height: 1.2em;
        color: var(--color-black);
        text-decoration: underline;
    }

    &__socials-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @media(--breakpoint-tablet-sm-up) {
            flex-direction: row;
        }
    }

    &__social-link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1;
        padding: 10px 0;
        color: var(--color-black);
        border: 1px solid var(--redesign-color-border);
        border-radius: 100px;

        &:hover {
            border-color: var(--color-secondary);
        }
    }

    .form-controls {
        flex: 1;
    }

    .btn-primary {
        flex: 1;
    }
}