/* ------------------------------------------------------------ *
	#fonts
* ------------------------------------------------------------ */
/*  #Roboto  */

@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-ThinItalic.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: "Roboto";
	src: url("../../fonts/Roboto-BlackItalic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-light-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-light-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-light-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-light-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-light-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-light-webfont.svg#hk_grotesklight") format("svg");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-lightitalic-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-lightitalic-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-lightitalic-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-lightitalic-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-lightitalic-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-lightitalic-webfont.svg#hk_grotesklight_italic") format("svg");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-regular-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-regular-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-regular-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-regular-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-regular-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-regular-webfont.svg#hk_groteskregular") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-italic-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-italic-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-italic-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-italic-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-italic-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-italic-webfont.svg#hk_groteskitalic") format("svg");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-medium-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-medium-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-medium-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-medium-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-medium-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-medium-webfont.svg#hk_groteskmedium") format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-mediumitalic-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-mediumitalic-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-mediumitalic-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-mediumitalic-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-mediumitalic-webfont.svg#hk_groteskmedium_italic") format("svg");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-bold-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-bold-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-bold-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-bold-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-bold-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-bold-webfont.svg#hk_groteskbold") format("svg");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "HK Grotesk";
	src: url("../../fonts/hkgrotesk-bolditalic-webfont.eot");
	src:
		url("../../fonts/hkgrotesk-bolditalic-webfont.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/hkgrotesk-bolditalic-webfont.woff2") format("woff2"),
		url("../../fonts/hkgrotesk-bolditalic-webfont.woff") format("woff"),
		url("../../fonts/hkgrotesk-bolditalic-webfont.ttf") format("truetype"),
		url("../../fonts/hkgrotesk-bolditalic-webfont.svg#hk_groteskbold_italic") format("svg");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Muller Bold";
	src: url("../../fonts/3433E1_0_0.eot");
	src:
		url("../../fonts/3433E1_0_0.eot?#iefix") format("embedded-opentype"),
		url("../../fonts/3433E1_0_0.woff2") format("woff2"),
		url("../../fonts/3433E1_0_0.woff") format("woff"),
		url("../../fonts/3433E1_0_0.ttf") format("truetype");
}

@font-face {
	font-family: "Sofia-sans";
	src: url("../../fonts/SofiaSans-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "Sofia-sans";
	src: url("../../fonts/SofiaSans-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Sofia-sans";
	src: url("../../fonts/SofiaSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
