#rebrand {
  .shopping-cart {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 1000;

    &.active {
      display: block;
    }

    &__container {
      position: absolute;
      max-width: 529px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px 0 0 10px;
      background-color: var(--color-white);

      @media (--breakpoint-tablet-sm) {
        height: 90%;
        top: unset;
        border-radius: 15px 15px 0 0;
      }
    }

    &__button {
      max-width: 14px;
      padding: 0;
    }

    &__head {
      padding: 16px;
      border-bottom: 1px solid var(--redesign-color-border);
    }

    &__message {
      font-size: 12px;
      color: var(--color-gray-dk);
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
    }

    &__list {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      scrollbar-width: none;

      -ms-overflow-style: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__bottom {
      padding: 25px 16px;
      margin-top: auto;
      border-top: 1px solid var(--redesign-color-border);

      &-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
        }

        p {
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          color: var(--redesign-color-black-md);
          span {
            font-size: 16px;
          }
        }
      }

      &-link {
        display: block;
        width: 100%;
        padding: 12px 0;
        color: var(--color-white);
        border-radius: 100px;
        background-color: var(--redesign-color-primary);
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
