#rebrand {
  .accent-news {
    &__title {
      margin-bottom: 40px;
      color: var(--redesign-color-black-md);
      font-size: 28px;
      text-align: center;

      @media (--breakpoint-desktop) {
        margin-bottom: 24px;
        font-size: 24px;
        text-align: left;
      }
    }

    &__container {
      @media (--breakpoint-desktop) {
        position: relative;
      }
    }

    &__box {
      display: grid;
      grid-template-columns: 1fr auto;
      max-width: 420px;
      width: 100%;
      background-color: var(--color-white-md);
      border-radius: 10px;

      @media (--breakpoint-desktop) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: 20px;

      &-title {
        margin-bottom: 6px;
        font-size: 16px;
        color: var(--redesign-color-black-md);
      }

      &-description {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        max-width: 184px;
        min-height: calc(14px * 3);
        margin-bottom: 14px;
        font-size: 14px;
        color: var(--redesign-color-black-md);
      }

      &-link {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: auto;
        text-transform: uppercase;
      }

      @media (--breakpoint-tablet) {
        min-height: 173px;
        height: 100%;
        padding: 16px;
      }
    }

    &__image {
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 10px 10px 0;
      }

      @media (--breakpoint-desktop) {
        img {
          border-radius: 10px 10px 0 0;
        }
      }
    }

    .swiper-wrapper {
      display: flex;
      flex-wrap: wrap;
      row-gap: 12px;

      @media (--breakpoint-desktop) {
        flex-wrap: nowrap;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;

      @media (--breakpoint-desktop) {
        display: block;
      }
    }

    .swiper-button-prev {
      right: 50px;
      left: auto;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
