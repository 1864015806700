.redesign-modal {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: block;
    width: 100%;
    max-height: 100vh;
    padding: 20px 16px;
    background-color: var(--color-white);
    border-radius: 15px 15px 0 0;
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out;
    z-index: 103;

    &__close {
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    &--wider {
        @media(--breakpoint-tablet-sm-up) {
            max-width: 510px;
        }
    }

    @media(--breakpoint-tablet-sm-up) {
        left: unset;
        right: 0;
        max-width: 430px;
        height: 100%;
        border-radius: 15px 0 0 15px;
        transform: translateX(100%);
    }
}

.redesign-modal.is-active {
    transform: translateY(0);

    @media(--breakpoint-tablet-sm-up) {
        transform: translateX(0);
    }
}