.expandable-list {
    color: var(--color-black);

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2em;
    }

    &__option {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px 0;
        width: 100%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid var(--redesign-color-border);
        cursor: pointer;
    }

    &__extra {
        display: none;
        text-align: left;
        font-size: 14px;
        line-height: 1.2em;
    }

    &__arrow {
        min-width: 12px;
        transform: rotate(180deg);
    }

    @media(--breakpoint-tablet-up) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 60px;
    }
}

.expandable-list__option--active > .expandable-list__extra {
    display: block;
}

.expandable-list__option--active > .expandable-list__info > .expandable-list__arrow {
    transform: none;
}