.optional-detail-fields {
    &__optional-group {
        position: relative;
        display: none;
        padding-bottom: 30px;

        &.active {
            display: block;
        }
    }

    &__btns-container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;

        label:not(:last-child) {            
            margin-right: 13px;
        }
    }

    &__choice-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 12px 20px;
        border: 1px solid var(--redesign-color-border);
        border-radius: 100px;

        &--error {
            border-color: var(--error);
        }

        img {
            margin: 0 auto;
        }
    }

    &__fields-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media(--breakpoint-tablet-up) {
            display: grid;
            row-gap: 20px;
            column-gap: 13px;
        }
        
        &--uneven {
            @media(--breakpoint-tablet-up) {
                grid-template-columns: 7fr 5fr;

                .form-controls:last-child {
                    grid-column: 1/-1;
                }
            }
        }
    }

    &__option-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 30px;

        @media(--breakpoint-tablet-up) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 14px;
        }
    }
}

.preset-details-option {
    flex-grow: 1;
    padding: 16px;
    background-color: var(--color-white-md);
    border: 1px solid var(--color-white-md);
    border-radius: 10px;
    cursor: pointer;

    &__head {
        color: var(--color-black);
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2em;
    }

    &__row {
        color: var(--color-gray-dk);

        span {
            color: var(--color-black);
        }
    }

    &:hover {
        border-color: var(--color-black);
    }
}

.preset-details-option.selected {
    background-color: var(--redesign-color-white-md);
    border-color: var(--redesign-color-primary);
}