#rebrand {
    @supports (height: 100svh) {
        .menu-mobile {
            min-height: calc(100svh - (var(--subhead-height, 55px) + var(--header-height, 62px)));
        }

        .menu-mobile__list {
            max-height: calc(100svh - (var(--subhead-height, 55px) + var(--header-height, 62px) + var(--mobile-menu-list-head, 68px)));
        }

        .menu-mobile-option__expand {
            min-height: calc(100svh - (var(--subhead-height, 55px) + var(--header-height, 62px) + var(--bottom-btn-height, 45px)));
        }
    }
    
    @supports (height: 100dvh) {
        .menu-mobile {
            min-height: calc(100dvh - (var(--subhead-height, 55px) + var(--header-height, 62px)));
        }

        .menu-mobile__list {
            max-height: calc(100dvh - (var(--subhead-height, 55px) + var(--header-height, 62px) + var(--mobile-menu-list-head, 68px)));
        }

        .menu-mobile-option__expand {
            min-height: calc(100dvh - (var(--subhead-height, 55px) + var(--header-height, 62px) + var(--bottom-btn-height, 45px)));
        }
    }
}

#rebrand .menu-mobile {
    --header-height: 62px;
    --subhead-height: 55px;
    --mobile-menu-list-head: 68px;
    --bottom-btn-height: 45px; 

    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    min-height: 100vh;    
    background-color: var(--color-white-md);

    &__title {
        padding: 20px 16px;
        border-bottom: 1px solid var(--redesign-color-border);
        font-size: 20px;
        line-height: 1.35em;
        font-weight: 600;
    }

    &__list {
        box-sizing: border-box;
        max-height: calc(100vh - (var(--subhead-height, 55px) + var(--header-height, 62px) + var(--mobile-menu-list-head, 68px)));
        overflow: auto;
        padding:11px 0 20px;
        list-style: none;
    }
}

#rebrand .menu-mobile.is-active {
    display: block;

    @media(--breakpoint-tablet-up) {
        display: none;
    }
}

#rebrand .menu-mobile-option {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 9px 16px;
    background-color: var(--color-white-md);
    font-size: 16px;
    line-height: 1.5em;
    color: var(--color-black);
    cursor: pointer;

    &__arrow {
        display: block;
    }

    &__group {
        overflow: auto;
    }

    &__arrow--selected {
        display: none;
    }

    &__expand {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-bottom: var(--bottom-btn-height, 45px);
        background-color: var(--color-white-md);
        min-height: calc(100vh - (var(--subhead-height, 55px) + var(--header-height, 62px) + var(--bottom-btn-height, 45px)));
        transform: translate(100%, 0);
        transition: all 0.3s ease-in-out;
    }

    &__head {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 12px 16px;
        border-bottom: 1px solid var(--redesign-color-border);
    }

    &__list {
        padding-top: 11px;
        overflow: auto;
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    &__close-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 0;
        background-color: transparent;
        font-size: 16px;
        line-height: 1.5em;
        color: var(--color-black);
        cursor: pointer;
    }

    &__bottom-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        height: var(--bottom-btn-height, 45px);
        border-top: 1px solid var(--redesign-color-border);
        background-color: var(--color-white);
        color: var(--color-black);
        text-transform: uppercase;

        &:hover {
            color: var(--color-black);
        }
    }

    &__preview-img {
        object-fit: cover;
        width: 100%;
        max-height: 33vh;
        border-radius: 5px;
    }
}

#rebrand .menu-mobile-option:hover {
    color: var(--color-white);
    background-color: var(--redesign-color-primary);
}

#rebrand .menu-mobile-option:hover > a {
    color: var(--color-white);
}

#rebrand .menu-mobile-option:hover > .menu-mobile-option__arrow {
    display: none;
}

#rebrand .menu-mobile-option:hover > .menu-mobile-option__arrow--selected {
    display: block;
}

#rebrand .menu-mobile-option.is-active {
    cursor: default;
}

#rebrand .menu-mobile-option.is-active > .menu-mobile-option__expand{
    transform: translate(0, 0);
}

#rebrand .menu-mobile-option a {
    color: var(--color-black);
}

#rebrand .level-3 .menu-mobile-option {
    &::after {
        background-image: url('');
    }
}