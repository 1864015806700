#rebrand {
  .accent-banner {
    position: relative;
    background-image: url("../../images/banner-background-desktop.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        align-items: start;
        gap: 20px;
      }
    }

    &__content {
      @media (--breakpoint-tablet-sm) {
        grid-column: 1;
        grid-row: 1;
      }
    }

    &__title {
      max-width: 367px;
      width: 100%;
      margin-bottom: 28px;
      font-size: 28px;
      font-weight: 600;
      color: #fff;

      @media (--breakpoint-tablet-sm) {
        max-width: 163px;
      }

      @media (--breakpoint-desktop) {
        max-width: 200px;
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__button {
      display: inline-block;
      padding: 12px 40px;
      background-color: #fff;
      border-radius: 100px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;

      @media (--breakpoint-desktop) {
        padding: 12px 28px;
      }
    }

    &__image {
      margin: 0 auto;

      img {
        display: block;
        max-width: 380px;
        width: 100%;
        height: 262px;
        object-fit: cover;
      }

      @media (--breakpoint-tablet-sm) {
        grid-column: 1 / span 2;
        grid-row: 2;
      }
    }

    &__apps {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 10px;

      &-item {
        max-width: 136px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media (--breakpoint-tablet-sm) {
        grid-column: 2;
        grid-row: 1;
      }

      @media (--breakpoint-desktop) {
        img {
          max-width: 122px;
        }
      }
    }

    @media (--breakpoint-tablet-sm) {
      padding-top: 44px;
      background-image: url("../../images/banner-background-mobile.png");
    }
  }
}
