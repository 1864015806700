.faq-page {
    background-color: var(--color-white-md);

    &__head {
        padding: 45px 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.2em;
    }

    &__container {
        padding-bottom: 80px;
    }

    &__content {
        padding-top: 30px;
        background-color: var(--color-white);
        border-radius: 40px;
    }
}