.order-details {
    padding-bottom: 80px;
    
    &__group-title {
        padding-bottom: 20px;
        color: var(--color-black);
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2em;
    }

    &__group {
        padding-bottom: 30px;
        color: var(--color-black);
    }

    &__fields-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media(--breakpoint-tablet-up) {
            display: grid;
            row-gap: 20px;
            column-gap: 13px;
        }

        &--even {
            @media(--breakpoint-tablet-up) {
                grid-template-columns: 1fr 1fr;
            }
        }
        
        &--uneven {
            @media(--breakpoint-tablet-up) {
                grid-template-columns: 1fr 1fr 1fr;

                .form-controls:last-child {
                    grid-column: 1/-1;
                }
            }
        }
    }

    &__checkbox-field {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }

    &__checkbox-container {
        padding-bottom: 16px;

        &--padding {
            padding: 16px 0 0 11px;
        }

        p.form-hint {
            padding-left: 30px;
        }
    }

    &__expandable-fields {
        display: none;
    }

    &__expandable-fields.active {
        display: block;
        padding-bottom: 32px;
    }

    &__expandable-trigger {
        display: inline-flex;
        align-items: flex-end;
        gap: 8px;
        padding: 20px 0;
    }

    &__choice-box:has(.custom-radio__input:checked) {
        border-color: var(--redesign-color-primary);
    }

    &__consent-info {
        line-height: 22px;
    }

    &__bottom-btns {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 8px;
        text-transform: uppercase;
        color: var(--color-black);
        font-weight: 600;
    }

    input::placeholder, textarea::placeholder {
        font-size: 14px;
        line-height: 1.2em;
    }
}