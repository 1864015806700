* { margin: 0; padding: 0; outline: 0; }

*:before,
*:after { box-sizing: border-box; }

*::-moz-focus-inner { border: 0; padding: 0; }
::-webkit-scrollbar { width: 4px; margin-left: -2px; }
::-webkit-scrollbar-track { background: #ffffff; }
::-webkit-scrollbar-thumb { width: 10px; max-height: 5%; background: var(--color-primary);  border-radius: 60px; pointer-events: none; }

*::selection { background: var(--color-primary); color: #fff; }

html { min-height: 100vh; width: 100%; tap-highlight-color: rgba(0, 0, 0, 0); }
body { min-height: 100vh; width: 100%; margin: auto; font-family: var(--font-primary); font-size: var(--font-size-primary); line-height: 1.3; color: var(--color-text); -moz-osx-font-smoothing: grayscale; font-smoothing: antialiased; text-size-adjust: none;}

a { color: var(--color-link); text-decoration: none; cursor: pointer; }
a:hover { text-decoration: underline; }

img,
iframe,
video,
audio,
object { max-width: 100%; }

article,
aside,
details,
figcaption,
figure,
picture,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary { display: block; }

img,
iframe { border: 0 none; }

img { height: auto; display: inline-block; vertical-align: top; transition: all 0.3s; user-select: none; }

table { border-collapse: collapse; border-spacing: 0; }

label:hover,
input[type="radio"]:hover,
input[type="checkbox"]:hover { cursor: pointer; }
textarea { overflow: auto; resize: none; overflow-scrolling: touch; }

button,
input,
select,
textarea { font-family: var(--font-primary); transition: all 0.3s; }


/* ------------------------------------------------------------ *
	#helpers
* ------------------------------------------------------------ */
.clearfix:after { content: ' '; display: block; clear: both; }

.show { display: block !important; }
.hide { display: none !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }

/* ------------------------------------------------------------ *\
	#containers
\* ------------------------------------------------------------ */
.container { position: relative; min-height: 100vh; width: 100%;}
.wrapper { width: var(--wrapper-width); margin: 0 auto; }
.wrapper:after { @apply --clearfix; }
.wrapper.extended { width: var(--wrapper-extended); margin: 0 auto; box-sizing: border-box; }
@media (max-width: 1365px) {
	.wrapper.extended { width: 100%; }
}

/* ------------------------------------------------------------ *\
	#general
\* ------------------------------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 { font-family: var(--font-secondary); font-weight: normal; color: var(--color-titles); }

#rebrand * {
	font-family: var(--font-rebrand);
	font-feature-settings: 'aalt' 0;
}

#rebrand .desktop-only {
	display: none;

	@media(--breakpoint-tablet-sm-up) {
		display: block;
	}
}

#rebrand .mobile-only {
	display: block;

	@media(--breakpoint-tablet-sm-up) {
		display: none;
	}
}

.logo { display: block; user-select: none; }
.logo img { max-width: 100%; height: auto; }

.tooltip { overflow: hidden; font-size: 100%; line-height: 1.35; white-space: nowrap; }

mark { background: var(--color-primary); color: #fff; }

.ribbon-promo .icon-svg { fill: var(--color-category-7); }
.fancybox-toolbar button:not(:last-child) { display: none !important; }

/* popups */
.popup { display: none; }
#cboxLoadedContent .popup { display: block; }

.popup { max-width: 665px; padding: 40px; }
.popup-checkout-success { text-align: center; }
.popup-checkout-success .btns { padding: 20px 0; }

.overlay-global { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #000; opacity: 0; visibility: hidden; transition: all 0.3s; pointer-events: none; }
body.no-scroll { overflow: hidden;}
body.no-scroll-mobile { overflow: hidden; @media(--breakpoint-tablet-up) { overflow: auto;}}
body.is-active .overlay-global { opacity: 0.7; visibility: visible; pointer-events: auto; }
body.modal-active .overlay-global { opacity: 0.7; visibility: visible; pointer-events: auto; z-index: 102;}
body.has-cart-active .overlay-global { /*pointer-events: none;*/ }
body.has-cart-active .footer { z-index: auto; }
body.is-active .has-cart-active,
body.is-active .header { z-index: 101;  }
body.is-active .accents,
body.is-active .main { z-index: auto; }
body.is-active .nav-cart-body { opacity: 1; z-index: 101; }
body.has-cart-active .nav-cart { z-index: 101; }
body.is-active .header-bottom .wrapper,
body.has-menu-active .header-bottom .wrapper { z-index: 101; }

/* z-index hierarchy */
.header,
.footer,
.main,
.accents { position: relative; }

.overlay-global { z-index: 100; }
/*.header { z-index: 35; }*/
/*.main { z-index: 30; }*/
.main,
.header { z-index: auto; }
.footer { z-index: 20; }
.accents { z-index: 10; }
.grecaptcha-badge { z-index: 30; }

.pad { overflow: hidden; background: #fff; border-radius: 5px; }
.pad-primary { box-shadow: var(--shadow-default); }
.pad-secondary { box-shadow: var(--shadow-blured); }
.pad-basic { border: 1px solid var(--color-neutral-mid); border-radius: 0; background: #f8f8f8; }

.toolbar:after { @apply --clearfix; }
.toolbar-date { padding: 10px 0; }
.toolbar-date .date { float: left; }
.toolbar-date .date strong { display: block; padding-bottom: 2px; font-size: 36px; line-height: 1; color: #000; }
.toolbar-date .date small { display: block; font-size: 10px; line-height: 1; color: #585858; }
.toolbar-date .btns { float: right; }
.toolbar-products { padding: 20px 0; }
.toolbar-products-top { padding-top: 0; }
.toolbar-products-bottom { padding-bottom: 0; }
.toolbar-products:after { @apply --clearfix; }
.toolbar-products .sort { float: left; }
.toolbar-products .sort .field-select select { height: 30px; border-radius: 5px; line-height: 30px; text-transform: none; }
.toolbar-products .paging { float: right; }
.toolbar-bordered { border-top: 1px solid #d6d6d6; padding: 15px 0; }

/* plugin: perfect scroll */
.ps > .ps__scrollbar-y-rail { opacity: 1; width: 8px; background: #dfdfdf; }
.ps.ps--active-y > .ps__scrollbar-y-rail { background: #dfdfdf; }
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y { right: 0; width: 8px; border-radius: 0; background: var(--color-secondary); }
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y, .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y { width: 8px; background: var(--color-secondary); }
.ps:hover > .ps__scrollbar-y-rail,
.ps:hover > .ps__scrollbar-y-rail:hover { background: #dfdfdf; opacity: 1; }
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps:hover.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y { width: 8px; }

/* plugin: nouislider */
.slider-range { height: 4px; border: 0; border-radius: 0; margin: 15px 55px 35px 10px; background: #dfdfdf; }
.slider-range .noUi-connect { background: #000; }
.slider-range .noUi-handle { top: 50%; left: 50%; width: 18px; height: 24px; border: 0; border-radius: 0; margin: -10px 0 0 -9px; background: url('../../images/icon-ellipse.svg') 0 0 no-repeat; box-shadow: none; cursor: pointer; }
.slider-range .noUi-handle:after { display: none; }
.slider-range .noUi-handle:before { content: attr(aria-valuetext); top: 100%; left: 0; width: 60px; height: auto; padding: 5px 0; background-color: transparent; font-size: 14px; line-height: 1; color: #000; }

/* plugin autocomplete */
.autocomplete-suggestions { overflow: auto; padding: 10px 0; border: 1px solid var(--color-neutral-mid); background: #fff; }
.autocomplete-suggestion { padding: 5px; }
.autocomplete-suggestion.autocomplete-selected { background: var(--color-neutral-mid); }

/* ------------------------------------------------------------ *\
	#grid
\* ------------------------------------------------------------ */
.cols:before,
.cols:after { content: " "; display: table; clear: both; }
.cols-full { margin-right: -15px; margin-left: -15px; }
.col { box-sizing: border-box; min-height: 1px; float: left; padding: 0 15px; }
.cols-no-gutters { margin: 0; }
.cols-no-gutters .col { padding: 0; }

.col1of12 { width: 8.33333333%; }
.col2of12 { width: 16.66666667%; }
.col3of12 { width: 25%; }
.col4of12 { width: 33.33333333%; }
.col5of12 { width: 41.66666667%; }
.col6of12 { width: 50%; }
.col7of12 { width: 58.33333333%; }
.col8of12 { width: 66.66666667%; }
.col9of12 { width: 75%; }
.col10of12 { width: 83.33333333%; }
.col11of12 { width: 91.66666667%; }
.col12of12 { width: 100%; }

.col1of10 { width: 10%; }
.col2of10 { width: 20%; }
.col3of10 { width: 30%; }
.col4of10 { width: 40%; }
.col5of10 { width: 50%; }
.col6of10 { width: 60%; }
.col7of10 { width: 70%; }
.col8of10 { width: 80%; }
.col9of10 { width: 90%; }
.col10of10 { width: 100%; }

@media (min-width: 1300px) {
	.col1of5 { width: 260px; }
	.col2of5 { width: 520px; }
	.col3of5 { width: 780px; }
	.col4of5 { width: 1040px; }
	.col5of5 { width: 1300px; }
}

@media (min-width: 1200px) {
	.col1of5 { width: 240px; }
	.col2of5 { width: 480px; }
	.col3of5 { width: 720px; }
	.col4of5 { width: 960px; }
	.col5of5 { width: 1200px; }
}

/* ------------------------------------------------------------ *
	#spacing
* ------------------------------------------------------------ */
.mb-0 {
	margin-bottom: 0 !important;
}

/* ------------------------------------------------------------ *
	#form elements
* ------------------------------------------------------------ */
legend { display: table; float: left; margin: 0; padding: 0; width: 100%; }
legend + * { clear: both; }
fieldset { border: 0; padding: 0.01em 0 0 0; min-width: 0; }

fieldset,
legend,
.field input,
.field select,
.field textarea { appearance: none; box-sizing: border-box; }

.field input[type='number'] { -moz-appearance:textfield; }
.field input[type=number]::-webkit-inner-spin-button,
.field input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }

.field select::-ms-expand { display: none; }

.field input,
.field select,
.field textarea { display: block; max-width: 100%; width: 100%; height: 44px; border: 1px solid var(--color-neutral-mid); padding: 11px; background: #fff; font-size: 14px; line-height: 20px; color: var(--color-text);  }
.field textarea { height: 80px; }
.field select { padding: 0 30px 0 20px; line-height: 40px; user-select: none; cursor: pointer; }
.field-select { position: relative; display: block; max-width: 100%; }
.field-select:before { content: ''; position: absolute; top: 50%; right: 10px; z-index: 2; display: block; width: 0; height: 0; border-width: 5px; border-style: solid; border-color: #000 transparent transparent; margin-top: -1px; pointer-events: none; }

.field textarea::placeholder,
.field input::placeholder { opacity: 1; color: var(--color-text); }

.field select:focus,
.field input:focus,
.field textarea:focus { border-color: var(--color-secondary); outline: none; }

.field select[disabled],
.field textarea[disabled],
.field input[disabled] { border-color: var(--color-neutral-mid); background: #eee; }

.choose { line-height: 1; }
.choose:after { content: ' '; display: block; clear: both; }

.radio,
.check { padding-bottom: 5px; }
.radio > input,
.check > input { float: left; width: 16px; height: 16px; margin-right: 10px; opacity: 0; }
.radio > label,
.check > label,
.check > span.brands  {font-size: 14px; line-height: 16px; color: #555; user-select: none; }
.radio > label:before,
.check > label:before { content: ''; position: absolute; top: 0; left: 0; display: block; width: 16px; height: 16px; border: 1px solid #000; background: #fff; transition: all 0.3s; pointer-events: none; }
.radio > label:after,
.check > label:after { content: ''; position: absolute; top: 0; left: 0; width: 20px; height: 20px; background: #000; transition: all 0.3s; opacity: 0; visibility: hidden; pointer-events: none; }
.radio > input:focus + label:before,
.radio > input:checked + label:before,
.radio > input:checked + label:after,
.check > input:checked + label:after { opacity: 1; visibility: visible; }
.radio > input[disabled] + label:after,
.check > input[disabled] + label:after { background: #eee; }
.radio > label:before { border-radius: 50%; }
.radio > label:after { border-radius: 50%; }


.choose,
.field { position: relative; }
.field.error > textarea,
.field.error > select,
.field.error > input { position: relative; border-bottom-color: var(--error); color: var(--error); }
/*.field.error:after { content: '!'; position: absolute; top: 50%; left: 7px; font-size: 20px; color: var(--error); font-weight: bold; transform: translateY(-50%); }*/
.choose.error > label { color: var(--error); }
.field.success > textarea,
.field.success > select,
.field.success > input { position: relative; border-bottom-color: var(--success); color: var(--success); }
/*.field.success:after { content: '✓'; position: absolute; top: 50%; left: 5px; width: 20px; height: 20px; color: var(--success); transform: translateY(-50%); }*/
.choose.success > label { color: var(--success); }
.select-error #delivery_store{ border-bottom:solid 1px #dc4e41;}

.page-title {
	padding-bottom: 24px;
	font-size: 28px;
	line-height: 1.2em;
	font-weight: 600;

	@media(--breakpoint-tablet-sm-up) {
		padding-bottom: 45px;
		font-size: 38px;
	}
}

/* ------------------------------------------------------------ *\
	#animations
\* ------------------------------------------------------------ */
@keyframes fadeIn {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes slideInDown {
	0% {
		transform: translate3d(0, -15px, 0);
		opacity: 0;
		visibility: hidden;
	}
	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
		visibility: visible;
	}
}

@keyframes scaleDown {
	0% {
		transform-origin: top;
		transform: scale3d(1, 0, 1);
	}
	100% {
		transform-origin: top;
		transform: scale3d(1, 1, 1);
	}
}

@keyframes enterLeft {
	0% {
		transform: translate3d(20%, 0, 0);
		opacity: 0;
		visibility: hidden;
	}
	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
		visibility: visible;
	}
}

@keyframes slideDown {
	0% {
		top: -100%;
	}
	100% {
		top: 0;
	}
}
@keyframes fade-flip-top {
	0% {
		-webkit-transform: rotateX(-180deg);
				transform: rotateX(-180deg);
	}
	100% {
		-webkit-transform: rotateX(0);
				transform: rotateX(0);
	}
}
@-webkit-keyframes fade-flip-top {
	0% {
		-webkit-transform: rotateX(-180deg);
				transform: rotateX(-180deg);
	}
	100% {
		-webkit-transform: rotateX(0);
				transform: rotateX(0);
	}
}

@keyframes bounce-x {
	0%   { transform: translateX(0); }
	50%  { transform: translateX(-70px); }
	100% { transform: translateX(0); }
}