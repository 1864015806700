#rebrand {
  .profile-view {
    &__wrapper {
      display: grid;
      grid-template-columns: 300px auto;
      gap: 30px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
      }
    }

    &__container {
      max-width: 970px;
      width: 100%;
      padding-top: 30px;
      overflow: hidden;
    }
  }
}
