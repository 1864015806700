#rebrand {
  .accent-products {
    margin-bottom: 80px;

    ul {
      @media (--breakpoint-tablet-sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: var(--redesign-color-border);;
          bottom: -5px;
          left: 0;
          right: 0;
        }
      }
    }

    .wrapper {
      @media (--breakpoint-tablet-sm) {
        padding: 0;
      }
    }

    .tabs-content {
      @media (--breakpoint-tablet-sm) {
        padding-left: 10px;
      }
    }

    @media (--breakpoint-tablet-sm) {
      margin-bottom: 50px;
    }
  }
}
