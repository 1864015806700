#rebrand {
    .terms-and-conditions {
        --list-bullet-point: url("data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDEzIDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHkxPSIxLjI1IiB4Mj0iMTMiIHkyPSIxLjI1IiBzdHJva2U9IiM5OUNGMjgiIHN0cm9rZS13aWR0aD0iMS41Ii8+Cjwvc3ZnPgo=");
        --line-height-paragraph: 16.8px;

        overflow-wrap: break-word;   
        word-wrap: break-word;
        word-break: break-word;     
        background-color: var(--color-white-md);

        &__head {
            padding: 45px 0;
        }

        &__content {
            border-radius: 20px 20px 0 0;
            background-color: var(--color-white);

            @media(--breakpoint-tablet-sm-up) {
                border-radius: 40px 40px 0 0;
            }
        }

        &__section {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0 6px 16px;
            color: var(--color-black);
            font-size: 14px;
            line-height: normal;
        }

        h4 {
            padding-top: 45px;
            padding-bottom: 12px;
            font-size: 16px;
            line-height: 1.2em;
            font-weight: 600;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 12px;
            list-style-type: none;
        }

        ul > li {
            display: flex;
            padding-left: 10px;
        }

        ul > li::before {
            display: block;
            content: var(--list-bullet-point);
            transform: translateY(calc(1.2em / -4));
            padding-right: 14px;
        }

        strong {
            font-weight: 600;
        }

        a {
            color: var(--color-black);
            text-decoration: underline;
        }
    }
}