#rebrand {
    #ui-datepicker-div {
        display: flex;
        flex-direction: column;
        background-color: var(--color-white);
        max-width: calc(100% - 45px);
        width: 100%;
        border-radius: 10px;
        border: 1px solid var(--redesign-color-border);
        font-family: var(--font-rebrand);

        td, th {
            text-align: center;
        }

        @media(--breakpoint-mobile-up) {
            max-width: 360px;
        }
    }

    .ui-datepicker-calendar {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .ui-datepicker-title {
        display: flex;
        justify-content: space-between;
        gap: 18px;
        
        @media(--breakpoint-mobile-up) {
            gap: 37px;
        }
    }

    .ui-datepicker-prev, .ui-datepicker-next {
        display: none;
    }

    .ui-datepicker-year, .ui-datepicker-month {
        padding: 10px 0;
        border: 0;
        background-color: transparent;
        font-size: 14px;
        line-height: 1.4em;
        color: var(--color-gray-dk);
    }

    .ui-datepicker-container {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 0;
        
        @media(--breakpoint-mobile-up) {
            gap: 20px;            
        }
    }

    .ui-state-default {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: var(--color-black);
        font-size: 14px;
        line-height: 1.5em;
        text-decoration: none;

        @media(--breakpoint-mobile-up) {
            width: 40px;
            height: 40px;
            font-size: 16px;
        }
    }

    .ui-state-default.ui-state-hover {
        background-color: var(--redesign-color-primary);
        color: var(--color-white);
    }

    .ui-state-default.ui-state-active {
        border: 1px solid var(--redesign-color-primary);
    }

    .ui-datepicker-calendar tbody td {
        padding: 5px 0;

        @media(--breakpoint-mobile-up) {
            padding: 10px 0;
        }
    }

    .ui-datepicker-title__btn {
        background-color: transparent;
        border: 0;
        padding: 8px 10px;
        cursor: pointer;
    }

    .other-month-day span {
        color: var(--color-gray-dk);
    }

    .datepicker-btn-container {
        display: flex;
        justify-content: space-between;
    }

    .datepicker-btn {
        padding: 12px 9px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        
        @media(--breakpoint-mobile-up) {
            padding: 24px 18px;
        }
    }
}