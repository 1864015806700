/* Hide the default radio button */
.custom-radio {
  &__input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  &__checkmark {
    position: relative;
    display: inline-block;
    min-height: 16px;
    min-width: 16px;
    background-color: var(--color-white);
    border-radius: 50%;
    margin-right: 18px;
    border: 1px solid var(--color-gray-dk);
  }
  
  /* Style the dot inside the radio button */
  &__input[type="radio"]:checked + &__checkmark {
    border-color: var(--redesign-color-primary); /* Border color when selected */
  }
  
  &__checkmark::after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: var(--redesign-color-primary);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Show the bullet when the radio button is selected */
  &__input[type="radio"]:checked + &__checkmark::after {
    display: block;
  }
}
  