.subhead {
    box-sizing: border-box;
    position: relative;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--redesign-color-border);
    z-index: 102;

    &__menu-btn {
        display: block;

        @media(--breakpoint-tablet-up) {
            display: none;
        }

        &--hide {
            display: none;
        }
    }

    &__menu-btn-desktop {
        display: none;

        @media(--breakpoint-tablet-up) {
            display: block;
        }

        &--selected {
            display: none;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        padding-top: 0;
        gap: 16px;

        @media(--breakpoint-tablet-up) {
            position: relative;
            padding-top: 8px;
        }

        @media(--breakpoint-desktop-xl-up) {
            position: unset;
        }

        @media(--breakpoint-desktop-xxl-up) {
            position: relative;
        }
    }

    &__dropdown {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    &__element-subgroup {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    &__dropdown-title {
        display: none;

        @media(--breakpoint-tablet-up) {
            display: block;
            font-size: 16px;
            font-weight: 600;
            color: var(--color-black);
        }
    }

    &__form-container {
        display: flex;
        align-items: center;
        gap: 13px;
        flex: 1;

        @media(--breakpoint-tablet-up) {
            padding-left: 104px;
        }
    }

    &__search-bar {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        width: 100%;
        max-width: 500px;
        padding: 10px 20px;
        border: 1px solid;
        border-radius: 100px;
    }

    &__search-field {
        width: 100%;
        max-width: 90%;
        background-color: transparent;
        border: 0;
        font-size: 16px;
        line-height: 1.7em;
        
        @media(--breakpoint-tablet-up) {
            line-height: 1.2em;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 1.7em;

            @media(--breakpoint-tablet-up) {
                line-height: 1.2em;
            }
        }
    }

    &__icons {
        display: flex;
        align-items: center;

        @media(--breakpoint-tablet-up) {
            gap: 20px;
            padding-right: 15px;
        }
    }

    &__logo {
        display: none;

        @media(--breakpoint-tablet-up) {
            display: block;
        }
    }

    &__icon {
        &--desktop {
            display: none;

            @media(--breakpoint-tablet-up) {
                display: block;
            }
        }
    }

    &__btn {
        display: block;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }

    &__cart-btn {
        position: relative;
        max-height: 25px;
        padding-right: 5px;

        &.active {
            svg {
                fill: var(--redesign-color-primary);
            }
        }
    }

    &__cart-count {
        position: absolute;
        top: -6px;
        right: -5px;
        width: 14px;
        height: 14px;
        padding: 3px;
        background-color: var(--redesign-color-primary);
        color: var(--color-white);
        border-radius: 50%;
    }

    &__close-btn {
        display: none;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }

    &__profile-alt {
        display: none;

        @media(--breakpoint-tablet-up) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            margin-right: 5px;
            border-radius: 50%;
            color: var(--color-white);
            background-color: var(--redesign-color-primary);
            text-transform: uppercase;
        }
    }

    &__profile-container {
        position: relative;
    }
}

.menu-open .subhead__menu-btn-desktop {
    display: none;
}

.menu-open .subhead__menu-btn-desktop--selected {
    display: block;
}

.menu-open .subhead__dropdown-title {
    color: var(--redesign-color-primary);
}

.search-open .subhead__search-bar{
    border: 1px solid var(--redesign-color-primary);
}

.search-open .subhead__close-btn {
    display: block;
}

.search-open .subhead__element-subgroup {
    display: none;

    @media(--breakpoint-tablet-up) {
        display: flex;
    }
}

.search-open .subhead__cart-btn {
    display: none;

    @media(--breakpoint-tablet-up) {
        display: block;
    }
}