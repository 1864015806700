#rebrand {
  .accent-images-swiper {
    padding-top: 20px;
    margin-bottom: 60px;

    &__container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;

      @media (--breakpoint-desktop) {
        grid-template-columns: 1fr;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (--breakpoint-desktop) {
        flex-direction: row;
        gap: 12px;
      }
    }

    &__item {
      min-width: 200px;
      height: 257px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        aspect-ratio: auto;
      }

      @media (--breakpoint-desktop-lg) {
        min-width: 158px;
        width: 100%;
        height: auto;
      }
    }

    .swiper-container {
      max-width: 100%;
      width: 100%;
      max-height: 533px;
      height: auto;
      overflow: hidden;

      @media (--breakpoint-desktop) {
        max-height: unset;
      }
    }

    .box-type-2 {
      flex-shrink: 0;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;

      img {
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      @media (--breakpoint-tablet-sm) {
        img {
          height: 420px;
        }
      }

      @media (--breakpoint-desktop) {
        max-width: 100%;

        img {
          display: block;
          min-width: 328px;
          width: 100%;
        }
      }
    }

    .swiper-pagination {
      position: absolute;
      bottom: 5%;
    }

    .swiper-pagination-bullet {
      border-color: #fff;
      opacity: 1;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 35px;
      height: 35px;
    }

    .swiper-button-next::after {
      content: url("../../images/next-button.svg");
    }

    .swiper-button-prev::after {
      content: url("../../images/prev-button.svg");
    }

    @media (--breakpoint-tablet-sm) {
      margin-bottom: 50px;
    }
  }
}
