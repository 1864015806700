#rebrand {
  .shopping-cart-item {
    position: relative;
    border-bottom: 1px solid var(--redesign-color-border);

    &.active {
      .shopping-cart-item__wrapper {
        opacity: 0.3;
      }

      .shopping-cart-message {
        display: block;
      }
    }

    &__wrapper {
      display: flex;
      align-items: start;
      gap: 16px;
      padding: 16px;
    }

    &__image {
      flex-basis: 20%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
    }

    &__container {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: "content content" "price counter";
      align-items: center;
      min-height: 80px;
    }

    &__button {
      max-width: 14px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    &__content {
      grid-area: content;
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin-bottom: 8px;

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 18px;
      }
    }

    &__title {
      max-width: 211px;
      width: 100%;
      font-size: 14px;
      line-height: normal;
      color: var(--redesign-color-black-md);
      text-align: left;
      text-transform: uppercase;
    }

    &__price {
      grid-area: price;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      color: var(--redesign-color-black-md);
      text-align: left;

      span {
        font-size: 18px;
      }
    }

    &__counter {
      grid-area: counter;

      .form-controls {
        .field-number {
          display: flex;
          align-items: center;
          justify-content: right;

        }

        input {
          max-width: 50px;
          height: auto;
          padding: 0;
          border: none;
        }

        .postfix {
          position: relative;
          top: unset;
          right: unset;
          transform: none;
          font-size: 12px;
          line-height: 13px;
          color: var(--color-gray-dk);
          text-transform: uppercase;
        }
      }
    }
  }
}
