#rebrand {
  .breadcrumbs {
    position: relative;

    &.secondary-bg {
      background-color: var(--color-white-md);
    }

    &.search {
      .breadcrumbs__item {
        a {
          text-transform: initial;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      background-color: var(--redesign-color-border);
      bottom: 0;
      width: 100%;
      height: 1px;
    }

    &__list {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 0;
      list-style: none;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      color: var(--color-gray-dk);

      a {
        color: var(--redesign-color-black-md);
        text-decoration: none;
      }

      @media (--breakpoint-tablet-sm) {
        display: none;

        &:nth-last-child(2) {
          display: flex;
        }

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
