.news-view {
    display: flex;
    flex-direction: column;
    color: var(--color-black);

    &__head-img {
        align-self: center;
        border-radius: 10px;
    }

    &__head-img--desktop {
        display: none;

        @media(--breakpoint-tablet-sm-up) {
            display: block;
            margin-bottom: 35px;
        }
    }

    &__head-img--mobile {
        margin-bottom: 30px;

        @media(--breakpoint-tablet-sm-up) {
            display: none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__content--bigger-gap {
        gap: 24px;
    }

    &__social-link:not(:last-of-type) {
        padding-bottom: 12px;
    }

    p {
        font-size: 14px;
        line-height: 1.2em;
    }

    &__list {
        list-style-type: disc;
        padding-left: 20px;
    }

    a {
        text-decoration: underline;
        color: var(--color-black);
    }

    &__list-container {
        padding-bottom: 24px;
    }

    &__category {
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 1.2em;
        font-weight: 600;
    }

    &__slider-container {
        padding-top: 40px;
    }

    &__contacts {
        list-style: none;
    }
}

#rebrand .news-view .accent-products-promo {
    margin-bottom: 40px;
}