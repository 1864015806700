#rebrand {
  .accent-products-last-seen {
    position: relative;
    margin-bottom: 50px;

    &__title {
      display: block;
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: 600;

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 25px;
        font-size: 24px;
        line-height: 30px;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 5%;

      &::after {
        font-size: 20px;
        font-weight: 600;
      }

      @media (--breakpoint-tablet-sm) {
        top: 3%;

        &::after {
          font-size: 16px;
        }
      }
    }

    .swiper-button-prev {
      right: 50px;
      left: auto;
    }
  }
}
