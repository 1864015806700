.redesign-alert {
    padding: 22px 20px;
    background-color: var(--color-white-md);
    color: var(--redesign-color-black-md);
    border-radius: 4px;

    &__head {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 14px;
        text-transform: uppercase;
        font-weight: 600;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &--row {
            flex-direction: row;
        }
    }
}