#rebrand {
    .info-box {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 20px;
        background-color: var(--color-white);
        border-radius: 10px;
    
        &__header {
            font-size: 18px;
            line-height: 1.2em;
            font-weight: 600;
        }
    
        &__text:last-of-type {
            margin-bottom: 12px;
        }
    
        &__link {
            align-self: flex-start;
            white-space: nowrap;
        }
    }
}