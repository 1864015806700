#rebrand {
    .redesign-form {
        position: relative;
        background-color: var(--color-neutral-lt);
    
        &__head {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 30px;
        }
    
        &__title {
            font-size: 20px;
            line-height: 1.2em;
            font-weight: 600;
        }
    
        &__subtitle {
            font-size: 14px;
            line-height: 1.2em;
        }

        &__overlay {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            background-color: rgba(247,247,247, 0.9);
            width: 100%;
            height: 100%;
            z-index: 101;

            &--hide {
                display: none;
            }

            @media (--breakpoint-tablet-up) {
                position: absolute;
            }
        }

        &__overlay-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            max-width: 50%;

            &--wider {
                text-align: center;
                max-width: 90%;
            }
        }

        &__overlay-head {
            font-weight: 600;
            font-size: 18px;
            line-height: 1.2em;
        }

        &__overlay-close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 30px;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }
    }
}