.section {
    &-type-1 {
        padding-top: 16px;

        @media(--breakpoint-tablet-up) {
            display: grid;
            grid-template-columns: 7.5fr 4.5fr;
            column-gap: 20px;
            padding-top: 20px;
        }
    }
}