#rebrand .search-results-heading {
    padding: 20px 26px;
    background-color: var(--color-white);
    font-size: 18px;
    line-height: 1.2em;

    @media(--breakpoint-tablet-sm) {
        padding: 20px 17px;
        font-size: 16px;
        line-height: 1.18em;
    }
}

#rebrand .search-results-container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid var(--color-neutral-mid);
    background: var(--color-neutral-lt);
    box-sizing: border-box;
    z-index: 40;
}

#rebrand .search-results {
    list-style: none;
    background: var(--color-white);
    box-sizing: border-box;
    max-height: 59vh;
    overflow: auto;
}

#rebrand {
    @supports (height: 100svh) {
        .search-results {
            max-height: 59svh;
        }
    }
    
    @supports (height: 100dvh) {
        .search-results {
            max-height: 59dvh;
        }
    }
}


#rebrand .search-results-categories {
    display: flex;
    overflow: auto;
    padding: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &__count {
        padding: 2px 7px;
        border-radius: 100px;
        background-color: var(--redesign-color-border);
        color: var(--color-black);
        font-size: 12px;
        line-height: 1.16em;
    }
    
    @media(--breakpoint-tablet-up) {
        padding: 0 var(--navigate-btn-width, 65px);
    }
}

#rebrand .search-results-categories input:checked+label .search-results-categories__count {
    background-color: var(--redesign-color-primary);
    color: var(--color-white);
}

#rebrand .search-results-categories input:checked+label {
    color: var(--color-black);
    border-bottom: 1px solid var(--redesign-color-primary);
}

#rebrand .search-results-categories input {
    display: none;
}

#rebrand .search-results-categories label {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
    padding: 16px 12px;
    text-wrap: nowrap;
    font-size: 16px;
    line-height: 1.18em;
}

#rebrand .search-results li {
    border-bottom: 1px solid var(--color-neutral-mid);
}

#rebrand .search-results-item {
    overflow: hidden;
    display: flex;
    padding: 8.5px 24px;
    font-size: var(--font-size-primary);
    line-height: 1.14em;
    text-decoration: none;
}

#rebrand .search-results-item:hover {
    background: var(--color-neutral-mid);
}

#rebrand .search-results-item .picture {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 103px;
    height: 82.5px;
    margin-right: 10px;
    background-color: var(--color-white);
    border: 1px solid var(--redesign-color-border);
    border-radius: 3px;
}

#rebrand .search-results-item .picture img {
    width: 75px;
    height: 75px;
}

#rebrand .search-results-item .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
    color: #000;
}

#rebrand .search-results-item .title {
    font-weight: 600;
    font-family: var(--font-rebrand);
    font-size: 16px;
    line-height: 1.18em;

    @media(--breakpoint-tablet-sm) {
        font-size: 14px;
        line-height: 1.2em;
    }
}

#rebrand .search-results-item .category {
    color: var(--color-gray-dk);
    font-size: 12px;
    line-height: 1.16em;

    @media(--breakpoint-tablet-sm) {
        font-size: 11px;
        line-height: 1.18em;
    }
}

#rebrand .search-results-item .content {
    padding: 4px 0;
}

#rebrand .search-results-item .price {
    display: flex;
    align-items: baseline;
    color: #000;
    font-weight: 700;

    strong {
        font-size: 18px;
        line-height: 1.2em;
    }

    small {
        font-size: 14px;
        line-height: 1.21em;
    }
}

#rebrand .search-results-bottom-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0;
    width: 100%;
    border: 0;
    border-top: 1px solid var(--redesign-color-border);
    background-color: var(--color-white);
    color: var(--color-black);
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 0.5px;
    cursor: pointer;
}

#rebrand .search-results-categories-container {
    --navigate-btn-width: 65px;

    position: relative;

    &__btn {
        position: absolute;
        top: 50%;
        height: 100%;
        width: var(--navigate-btn-width, 65px);
        border: 0;
        transform: translate(0, -50%);
        cursor: pointer;

        @media(--breakpoint-tablet-sm) {
            display: none;
        }
    }

    &__back-btn {
        left: 0;
        background: linear-gradient(to left, rgba(247, 247, 247, 0.5), #f7f7f7);
    }

    &__forward-btn {
        right: 0;
        background: linear-gradient(to right, rgba(247, 247, 247, 0.5), #f7f7f7);
    }
}

#rebrand .search-results-empty {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    min-height: 62px;
    padding: 20px 26px;
    background-color: var(--color-white);
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 600;
    color: var(--redesign-color-black-md);
}

#rebrand.search-open .search-results-container {
    display: block;
}