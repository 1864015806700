#rebrand {
  .success-message {
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 0;
    left: 0;
    right: 0;
    padding: 12px 0;
    color: var(--redesign-color-primary);
    background-color: var(--redesign-color-white-md);
    border-radius: 8px;
    border-top: 1px solid var(--redesign-color-primary);
    border-bottom: 1px solid var(--redesign-color-primary);
    z-index: 20;

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    &__info {
      font-size: 18px;
      font-weight: 600;
    }

    @media (--breakpoint-tablet-sm) {
      border-radius: 0;
    }
  }
}
