#rebrand {
  .products-listing {
    position: relative;
    background-color: var(--color-white-md);

    &__head {
      padding: 35px 0;
    }

    &__content {
      &-title {
        font-size: 28px;
        font-weight: 600;

        @media (--breakpoint-tablet-sm) {
          font-size: 24px;
        }
      }
    }

    &__wrapper {
      padding-top: 50px;
      border-radius: 40px 40px 0 0;
      background-color: var(--color-white);

      @media (--breakpoint-tablet-sm) {
        padding-top: 25px;
        border-radius: 25px 25px 0 0;
      }
    }

    &__mobile-menu {
      display: none;
      justify-content: center;
      gap: 10px;
      margin-bottom: 24px;
      padding: 0 16px;

      .nav-aside-head {
        padding: 0;
        background: transparent;

        &::before,
        &::after {
          content: none;
        }
      }

      .filters-aside-head {
        @media (--breakpoint-desktop) {
          display: block;
        }
      }

      .categories-menu,
      .filters-menu {
        max-width: 134px;
        width: 100%;

        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 12px 0;
          color: var(--redesign-color-black-md);
          background-color: transparent;
          border-radius: 100px;
          border: 1px solid var(--redesign-color-border);
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.42px;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      @media (--breakpoint-tablet-sm) {
        display: flex;
        justify-content: center;
      }

      @media (--breakpoint-tablet-sm-up) and (--breakpoint-desktop) {
        display: flex;
        justify-content: start;
      }
    }

    &__sort-menu {
      button {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;
        background-color: transparent;
        border-radius: 100%;
        border: 1px solid var(--redesign-color-border);
        cursor: pointer;
      }
    }

    &__scroll {
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 50%;
      width: 40px;
      height: 40px;
      margin-left: auto;
      border-radius: 40px;
      cursor: pointer;
      z-index: 10;

      &::after {
        content: none;
      }
    }
  }
}
