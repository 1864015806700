#rebrand {
  .no-order-item {
    position: relative;
    padding-top: 94px;

    &__wrapper {
      max-width: 364px;
      margin: 0 auto;
      text-align: center;
    }

    &__image {
      max-width: 66px;
      width: 100%;
      margin: 0 auto 20px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (--breakpoint-desktop) {
        max-width: 54px;
      }
    }

    &__title {
      // todo: add font-family
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
    }

    &__description {
      margin-bottom: 20px;
      font-size: 14px;
      color: #000;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 188px;
      width: 100%;
      margin: 0 auto;
      padding: 9px 0;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      border-radius: 100px;
      background-color: #99cf28;
      text-transform: uppercase;
    }

    @media (--breakpoint-desktop) {
      padding: 25px 0 50px;
    }
  }
}
