.custom-dropdown {
    position: relative;
    user-select: none;
    cursor: pointer;

    &__selection-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border: 1px solid var(--redesign-color-border);
        border-radius: 100px;
        color: var(--color-gray-dk);

        &:hover {
            border-color: var(--redesign-color-primary);
        }
    }

    &__arrow {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 90%;
        overflow: auto;
        max-height: 0;
        background-color: var(--color-white);
        border: 1px solid var(--redesign-color-border);
        border-top: 0;
        border-bottom: 0;
        border-radius: 8px;
        opacity: 0; 
        transform: translateX(-50%);
        transition: opacity 0.3s ease, max-height 0.3s ease;
        list-style: none;
        z-index: 21;
        pointer-events: none;
    }

    &__option {
        padding: 10px;
        border-bottom: 1px solid var(--redesign-color-border);
        cursor: pointer;
        user-select: none;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            color: var(--redesign-color-primary);
            border-color: var(--redesign-color-primary);
        }

        &.selected {
            color: var(--color-white);
            background-color: var(--redesign-color-primary);
        }
    }

    &--error {
        border-color: var(--error);

        &:hover {
            border-color: var(--error);
        }
    }
}

.custom-dropdown.active .custom-dropdown__arrow {
    transform: rotate(0);
}

.custom-dropdown.active .custom-dropdown__list {
    opacity: 1;
    max-height: 200px;
    pointer-events: all;
}

.custom-dropdown-blueprint {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    pointer-events: none;
}