#rebrand .header {
	box-sizing: border-box;
	width: 100%;
	background: var(--color-white);

	@media(--breakpoint-tablet-up) {
		padding: 11px 0;
		background-color: var(--color-white-md);
		z-index: 101;
	}
}

#rebrand .header__container {
	display: flex;
	justify-content: space-between;
	padding: 8px 0;

	@media(--breakpoint-tablet-up) {
		justify-content: flex-end;
		padding: 0;
	}
}

#rebrand .header__icons {
	display: flex;
	align-items: center;
	gap: 15.9px;
}

#rebrand .header__icon-container {
	display: flex;
	gap: 4.7px;
	color: var(--color-gray-dk);
}

#rebrand .header__logo {
	@media(--breakpoint-tablet-up) {
		display: none;
	}
}

#rebrand .header__icon-description {
	display: none;

	@media(--breakpoint-tablet-up) {
		display: block;
	}
}

#rebrand .header__profile-icon {
	@media(--breakpoint-tablet-up) {
		display: none;
	}
}

#rebrand .header__profile-alt {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	color: var(--color-white);
	background-color: var(--redesign-color-primary);
	text-transform: uppercase;
}

#rebrand .header__icon--mobile {
	@media(--breakpoint-tablet-up) {
		display: none;
	}
}

#rebrand .header__icon--desktop {
	display: none;
	
	@media(--breakpoint-tablet-up) {
		display: block;
	}
}
