#rebrand {
  .shopping-cart-message {
    display: none;
    position: relative;
    background-color: #ffe8e5;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 12px;
    }

    &__info {
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      color: var(--redesign-color-red);
    }

    &__button {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.42px;
      color: var(--redesign-color-red);
      text-transform: uppercase;
    }

    &__loading {
      width: 100%;
      height: 3px;
      background-color: var(--redesign-color-border);
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: var(--redesign-color-red);
        animation: loading-line 5s linear infinite;
      }
    }

    .btn {
      width: auto;
    }

    @keyframes loading-line {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}
