#rebrand {
  .delete-account-message {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 1000;

    &.active {
      display: block;
    }

    &__container {
      position: absolute;
      max-width: 356px;
      width: 100%;
      margin: 0 auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      background-color: var(--color-white);

      @media (--breakpoint-tablet-sm) {
        max-width: 100%;
        top: unset;
        left: 0;
        bottom: 0;
        transform: none;
        border-radius: 15px 15px 0 0;
      }
    }

    &__content {
      max-width: 288px;
      width: 100%;
      padding: 30px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (--breakpoint-tablet-sm) {
        max-width: 329px;
        padding: 30px 16px;
      }
    }

    &__image {
      max-width: 40px;
      margin-bottom: 20px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
      color: var(--redesign-color-black-md);
      text-align: center;
    }

    &__info {
      margin-bottom: 20px;
      color: var(--color-black);
      font-size: 14px;
      text-align: center;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    &__confirm-button,
    &__cancel-button {
      min-width: 139px;
      width: 100%;
      padding: 9px 0;
      font-size: 14px;
      font-weight: 700;
      border-radius: 100px;
      text-transform: uppercase;
    }

    &__confirm-button {
      color: var(--redesign-color-red);
      border: 1px solid var(--redesign-color-red);
    }

    &__cancel-button {
      color: var(--redesign-color-black-md);
      border: 1px solid var(--redesign-color-border);
    }
  }
}
