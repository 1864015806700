#rebrand {
  .accent-products-popular {
    &__nav {
      position: relative;
      display: flex;
      align-items: center;

      @media (--breakpoint-tablet-sm) {
        margin-bottom: 25px;
      }
    }

    &__title {
      display: none;

      @media (--breakpoint-tablet-sm) {
        display: block;
        font-size: 24px;
        line-height: 30px;
      }
    }

    .box-basic {
      min-width: 310px;
      width: 100%;
      outline: none;
      border: 1px solid var(--redesign-color-border);
      border-radius: 10px;
      background: var(--color-white);

      @media (--breakpoint-tablet-sm) {
        min-width: 243px;
      }
    }

    .box-type-2 {
      padding: 19px 25px 0;

      @media (--breakpoint-tablet-sm) {
        padding: 12px 12px 0;

        .picture {
          min-height: 195px;
          max-height: 200px;
          height: 100%;
        }
      }
    }

    .ribbon {
      padding: 0;
      left: 10px;

      @media (--breakpoint-tablet-sm) {
        padding: 0;
        left: 0;
      }
    }

    .title {
      margin-bottom: 12px;
    }

    .box-price-wrap {
      border-color: var(--redesign-color-border);
    }

    .box-price-wrap {
      text-align: left;
    }

    .box-price {
      vertical-align: unset;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 0;
      transform: translateY(-100%);

      &::after {
        font-size: 20px;
        font-weight: 600;
      }

      @media (--breakpoint-tablet-sm) {
        width: auto;
        height: auto;
        top: unset;
        margin-top: 0;
        transform: none;

        &::after {
          font-size: 16px;
        }
      }
    }

    .swiper-button-prev {
      right: 50px;
      left: auto;
    }
  }
}
