#rebrand {
  .accent-promo-offers {
    margin-bottom: 80px;

    &__top {
      position: relative;
    }

    &__title {
      margin-bottom: 37px;
      font-size: 28px;
      font-weight: 600;
      color: #251f20;
      text-align: center;

      @media (--breakpoint-tablet-sm) {
        font-size: 24px;
        text-align: left;
      }
    }

    &__item {
      &-image {
        max-width: 420px;
        width: 100%;
        height: 420px;
        
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }

        @media (--breakpoint-tablet-sm) {
          max-width: 243px;
          height: 243px;
        }
      }
    }

    .wrapper {
      @media (--breakpoint-tablet-sm) {
        padding-right: 0;
      }
    }

    .swiper-wrapper {
      width: max-content;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;

      @media (--breakpoint-tablet-sm) {
        display: flex;

        &::after {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    .swiper-button-prev {
      @media (--breakpoint-tablet-sm) {
        left: auto;
        right: 50px;
      }
    }

    .box-basic {
      width: 100%;
    }

    .box-type-2 {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
    }
  }
}
