#rebrand {
  .profile-orders {
    &__wrapper {
      display: grid;
      grid-template-columns: 300px auto;
      column-gap: 30px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
      }
    }

    &__container {
      padding-top: 35px;
    }

    &__title {
      margin-bottom: 24px;
      // todo: add font-family
      font-size: 28px;
      font-weight: 600;
      color: #251f20;

      @media (--breakpoint-tablet-sm) {
        font-size: 24px;
      }
    }
  }
}
