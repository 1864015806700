#rebrand {
    .contacts {
        padding: 45px 0;
    
        &__container {
            position: relative;
            display: flex;
            flex-direction: column;
    
            @media(--breakpoint-tablet-up) {
                flex-direction: row;
            }
        }
    
        &__map-container {
            position: relative;
        }
    
        &__map {
            width: 100%;
            height: 905px;
    
            @media(--breakpoint-tablet-up) {
                width: 420px;
                height: 100%;
            }
        }
    
        &__info-box {
            position: absolute;
            bottom: 16px;
            left: 50%;
            width: 85%;
            transform: translateX(-50%);
            z-index: 1;
    
            @media(--breakpoint-tablet-up) {
                bottom: 20px;
            }
        }
    
        &__form {
            margin-right: calc(-1 * var(--wrapper-padding));
            margin-left: calc(-1 * var(--wrapper-padding));
            padding: 40px var(--wrapper-padding);
    
            @media(--breakpoint-tablet-up) {
                margin: unset;
                padding: 40px;
            }
        }
    
        &__submit-btn.btn {
            width: 100%;
    
            @media(--breakpoint-mobile-up) {
                width: unset;
            }
        }
    
        &__field-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    
        &__field-container:first-of-type {
            padding-bottom: 20px;
        }
    
        &__field-container--split {
            @media(--breakpoint-tablet-sm-up) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    
        &__btns {
            padding-top: 24px;
        }
    
        @media(--breakpoint-tablet-sm-up) {
            padding-bottom: 120px;
        }
    }
}