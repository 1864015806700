.error-page {
    position: relative;
    padding: 16px 0;
    &__image {
        img{
            border-radius: 20px;
        }
    }

    &__content {
        position: absolute;
        top: 16px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding: 20px;
        color: var(--color-white);

        @media(--breakpoint-tablet-up) {
            top: 50%;
            left: 10%;
            align-items: center;
            transform: translateY(-50%);
        }
    }

    &__head {
        font-size: 50px;
        line-height: 1.2em;
        font-weight: 600;
        color: var(--color-white);

        @media(--breakpoint-tablet-up) {
            font-size: 160px;
        }
    }

    &__subhead {
        font-size: 20px;
        line-height: 1.2em;
        font-weight: 600;
        color: var(--color-white);

        @media(--breakpoint-tablet-up) {
            font-size: 32px;
        }
    }

    &__paragraph {
        padding-bottom: 8px;
    }
}