#rebrand {
  .cart-popup {
    display: none;
    position: absolute;
    top: 30px;
    min-width: 397px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    border: 1px solid var(--redesign-color-border);
    background-color: var(--color-white);
    z-index: 10;

    &.active {
      display: block;

      .cart-popup__close {
        @media (--breakpoint-tablet-sm) {
          display: flex;
          justify-content: end;
          margin-bottom: 30px;
        }
      }
    }

    &__element {
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 8px;
      height: 8px;
      background-color: var(--color-gray-dk);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 7px;
        height: 8px;
        background-color: var(--color-white);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      }

      @media (--breakpoint-tablet-sm) {
        display: none;
      }
    }

    &__close {
      display: none;
    }

    &__container {
      padding: 16px;

      @media (--breakpoint-tablet-sm) {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 16px 16px 30px;
        border-radius: 15px 15px 0 0;
        background-color: var(--color-white);
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 12px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: var(--redesign-color-black-md);
      }

      p {
        font-size: 16px;
        font-weight: 700;
        color: var(--redesign-color-black-md);
      }

      span {
        font-size: 12px;
        font-weight: 700;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: start;
      gap: 8px;
      padding-top: 16px;
      border-top: 1px solid var(--redesign-color-border);

      > p {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-gray-dk);
        text-align: left;
      }
    }

    @media (--breakpoint-tablet-sm) {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      min-width: 100%;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: none;
      background-color: rgba(0, 0, 0, 0.5);
      transform: none;
      overflow: hidden;
      z-index: 1000;
    }
  }
}
