#rebrand {
  .product-preview-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);

    &__container {
      position: relative;
      background-color: #fff;
      margin: 0 auto;
      padding: 20px;
      width: 80%;
      border-radius: 10px;
      top: 50%;
      transform: translateY(-50%);

      @media (--breakpoint-desktop) {
        padding: 12px;
      }
    }

    &__title {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      text-transform: uppercase;

      @media (--breakpoint-desktop) {
        max-width: 211px;
        width: 100%;
        font-size: 16px;
      }
    }

    &__close {
      position: absolute;
      top: 24px;
      right: 24px;
      border: 0;
      background-color: transparent;
      cursor: pointer;

      @media (--breakpoint-desktop) {
        top: 14px;
        right: 12px;
        width: 35px;
        height: 35px;
      }
    }

    .slider-for {
      margin-bottom: 32px;
    }

    .slick-slide {
      img {
        margin: 0 auto;
        max-width: 542px;
        height: 417px;

        @media (--breakpoint-desktop) {
          max-width: 304px;
          height: 278px;
        }
      }

      &:hover {
        img {
          transform: scale3D(1.1, 1.1, 1.1);
          transform-style: preserve-3d;
        }
      }
    }

    .slick-next,
    .slick-prev {
      width: 35px;
      height: 35px;
      top: 50%;
      z-index: 10;

      &::before {
        content: url("../../images/product-swiper-arrow.svg");
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        opacity: 1;
      }
    }

    .slick-next {
      right: 0;
      transform: translateY(-50%);
    }

    .slick-prev {
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }

    .slider-nav {
      padding-top: 12px;
      border-top: 1px solid #dbdbdb;

      .slick-slide {
        width: fit-content !important;

        img {
          border-radius: 4px;
          border: 2px solid #dbdbdb;
          width: 85px;
          height: 85px;
        }

        &.slick-current {
          img {
            border: 2px solid #99cf28;
          }
        }
      }
    }

    .slick-track {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
  }
}
