.image-slider {
    margin-right: calc(-1 * var(--wrapper-padding));

    &__slide {
        max-width: 284px;
        height: 284px;
        object-fit: cover;
        border-radius: 10px;

        @media(--breakpoint-tablet-sm-up) {    
            max-width: unset;        
            width: 310px;
            height: 310px;
        }
    }

    @media(--breakpoint-tablet-sm-up) {
        margin-right: unset;
    }
}

.image-slider.swiper-container.inactive .image-slider__wrapper {
    display: flex;
    gap: 20px;
}