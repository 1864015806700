#rebrand {
    .stores {
        --color-scrollbar-bg: #EDEDED;

        padding: 45px 0 80px 0;

        &__container {
            position: relative;
        }

        &__slider {
            overflow: hidden;
            max-width: 100%;
            padding-top: 20px;
            cursor: grab;
        }

        &__overlay {
            display: none;

            @media(--breakpoint-tablet-sm-up) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 85%;
                display: block;
                background: linear-gradient(to right, transparent, var(--color-white));
                pointer-events: none;
                z-index: 2;
            }
        }
        
        &__map { 
            max-width: 100%; 
            min-height: 370px;
            border-radius: 10px;

            @media(--breakpoint-tablet-sm-up) {
                min-height: 417px;
            }
        }

        .swiper-scrollbar {
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            height: 7px;
            background-color: var(--color-scrollbar-bg);;
            z-index: 10;
        }
    }

    .stores .stores__overlay.hide {
        @media(--breakpoint-tablet-sm-up) {
            display: none;
        }
    }

    .store-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        min-width: 243px; 
        height: auto;
        padding: 16px; 
        background-color: var(--color-white-md);
        color: var(--redesign-color-black-md); 
        border: 1px solid var(--redesign-color-border);
        border-radius: 10px;
        cursor: pointer;

        &__info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: 1px solid var(--redesign-color-border);
        }

        &__head {
            padding-bottom: 8px;
            font-size: 20px;
            line-height: 1.2em;
            font-weight: 600;
        }

        &__text {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-bottom: 4px;
            font-size: 14px;
            line-height: 1.2em;

            &:last-child {
                margin-bottom: 12px;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            gap: 7px;
            padding-top: 16px;
        }

        &__subcategory {
            font-size: 12px;
            line-height: 1.2em;
        }

        &__table {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 5px;
        }

        &__align-text {
            text-align: right;
        }

        &__richtext-field {
            grid-column: 1 / 3;

            & > div {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }

        &:hover, &:focus, &.selected {
            border-color: var(--redesign-color-primary);
        }
    }
}