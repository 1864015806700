#rebrand {
  .product-categories-section {
    padding-bottom: 100px;

    &__head {
      margin-bottom: 45px;
      background-color: var(--color-white-md);
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;

      &-title {
        font-size: 35px;
        color: var(--redesign-color-black-md);

        span {
          color: var(--redesign-color-primary);
        }
      }

      @media (--breakpoint-tablet-sm) {
        flex-direction: column;
        align-items: normal;
        gap: 24px;

        &-image {
          display: block;
          max-width: 305px;
          height: 110px;
          margin: 0 auto;
          transform: scaleX(-1);
        }
      }
    }

    &__container {
      display: grid;
      grid-template-columns: 300px auto;
      gap: 30px;

      @media (--breakpoint-tablet-sm) {
        grid-template-columns: 1fr;
      }
    }

    &__sidebar {
      display: block;

      @media (--breakpoint-tablet-sm) {
        display: none;
        &.active {
          display: block;
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          overflow: hidden;
        }
      }
    }

    &__elements {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @media (--breakpoint-desktop) {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
      }
    }

    &__box {
      position: relative;
      height: fit-content;
      color: var(--color-white);

      .picture {
        img {
          border-radius: 10px;
        }
      }

      &-info {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 20px;
        font-weight: 600;
      }

      @media (--breakpoint-tablet-sm) {
        &-info {
          top: 12px;
          left: 12px;
          font-size: 14px;
        }
      }
    }

    .categories-menu {
      display: none;

      @media (--breakpoint-tablet-sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        padding: 12px 0;
        color: var(--redesign-color-black-md);
        background-color: transparent;
        border-radius: 100px;
        border: 1px solid var(--redesign-color-border);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.42px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    &__content-image svg {
      width: 328px;
      height: 107px;
    }

    @media (--breakpoint-tablet-sm) {
      padding-bottom: 50px;
    }
  }
}
