.shopping-cart-preview-product {
    display: flex;
    min-height: 140px;
    border-radius: 10px;
    background-color: var(--color-white);

    &__title {
        box-sizing: border-box;
        overflow: hidden;
        height: calc(3 * 1.2em);
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.2em;

        @media(--breakpoint-tablet-up) {
            height: unset;
        }
    }

    &__img-container {
        display: flex;
        align-items: center;
        border-right: 1px solid var(--redesign-color-border);
        margin-right: 12px;

        &--no-border {
            border: 0;

            @media(--breakpoint-tablet-sm-up) {
                border: 1px solid var(--redesign-color-border);
                border-radius: 3px;
            }
        }

        @media(--breakpoint-tablet-up) {
            border: 1px solid var(--redesign-color-border);
            border-radius: 3px;
        }
    }

    &__img {
        width: 153px;
        max-height: 120px;

        &--small{
            width: 73px;

            @media(--breakpoint-tablet-sm-up) {
                width: 153px;
            }
        }
    }

    &__color {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 2px;
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 4px;
        color: var(--color-black);
        font-size: 12px;
        line-height: 1.2em;

        &--row {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            @media(--breakpoint-tablet-sm-up) {
                flex-direction: column;
            }
        }

        @media(--breakpoint-tablet-up) {
            display: flex;
            flex-direction: column;
            color: var(--color-gray-dk);

            span {
                color: var(--color-black);
            }
        }
    }

    &__align-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        grid-area: bottom;
    }

    &__focus {
        align-self: flex-end;
        font-weight: 700;

        span {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.2em;
        }

        @media(--breakpoint-tablet-up) {
            align-self: flex-start;
            color: var(--color-black);
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        flex: 1;
        padding: 12px 16px 12px 0;

        @media(--breakpoint-tablet-up) {
            display: flex;
            flex-direction: column;
        }
    }

    &__subgroup {
        display: flex;
        align-items: center;

        &--column {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;

            @media(--breakpoint-tablet-sm-up) {
                flex-direction: row;
                align-items: center;
                width: 100%;
            }
        }
    }

    &__stat:not(:last-child) {
        padding-right: 8px;
        border-right: 1px solid var(--redesign-color-border);
    }

    &__stat:not(:first-child) {
        padding-left: 8px;
    }

    &__stat.shopping-cart-preview-product__stat--column {
        @media(--breakpoint-tablet-sm) {
            border: 0;
            padding: 0;
            white-space: nowrap;
        }
    }

    @media(--breakpoint-tablet-up) {
        min-height: unset;
        background-color: var(--color-white-md);
    }
}

.shopping-cart-preview-product.shopping-cart-preview-product--bg-white {
    @media(--breakpoint-tablet-up) {
        background-color: var(--color-white);
    }
}