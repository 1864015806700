#rebrand {
    .privacy-consent {
        display: flex;
        gap: 9px;
    
        &__notice {
            color: var(--color-black);
        }
        
        &__policy {
            display: block;
        }
    }
}