.benefit-list {
    padding-bottom: 24px;
    list-style: none;

    &__row {
        display: flex;
        align-items: center;
        gap: 13px;
    }

    &__row:not(:last-of-type) {
        padding-bottom: 10px;
    }

    &__icon {
        display: block;
        min-width: 19px;
    }
}