.fast-order-modal {
    display: flex;
    flex-direction: column; 

    &__head {
        display: flex;
        justify-content: space-between;
        align-content: center;
        color: var(--redesign-color-black-md);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2em;

        @media(--breakpoint-desktop-up) {
            line-height: 1.5em;
        }
    }

    &__alert-container {
        font-size: 14px;
        line-height: 1.2em;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        flex-grow: 1;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex-grow: 1;
    }

    &__field-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2em;
    }
}