#rebrand {
  .profile-nav {
    position: sticky;
    top: 0;
    height: fit-content;
    padding: 50px 0;
    border-radius: 0px 10px 10px 0px;
    background-color: #f7f7f7;
    z-index: 10;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: #f7f7f7;
      z-index: -1;
    }

    &__info {
      margin-bottom: 74px;

      @media (--breakpoint-tablet-sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
      }
    }

    &__element {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-bottom: 12px;
      border-radius: 100%;
      font-size: 14px;
      color: #fff;
      background-color: #99cf28;
      text-transform: uppercase;
    }

    &__title {
      // todo: add font-family
      font-size: 16px;
      font-weight: 600;
      color: #251f20;
    }

    &__mail {
      font-size: 14px;
      color: #251f20;
    }

    &__container {
      @media (--breakpoint-tablet-sm) {
        padding: 0 10px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 23px;
      list-style: none;

      @media (--breakpoint-tablet-sm) {
        flex-direction: row;
        gap: 12px;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__item {
      &.active {
        a {
          font-weight: 600;
          color: #99cf28;

          @media (--breakpoint-tablet-sm) {
            border: 1px solid #99cf28;
          }
        }

        svg {
          display: none;

          &.active {
            display: block;
          }
        }
      }

      &.exit {
        padding-top: 140px;

        @media (--breakpoint-tablet-sm) {
          padding-top: 0;

          a {
            border: 1px solid transparent;
            background-color: #fff;
          }
        }
      }

      > a {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        line-height: 1.2;
        color: #251f20;

        @media (--breakpoint-tablet-sm) {
          justify-content: center;
          padding: 8px 20px;
          width: 100%;
          border-radius: 100px;
          border: 1px solid #dbdbdb;
          white-space: nowrap;
          box-sizing: border-box;
        }
      }

      svg.active {
        display: none;
      }
    }

    @media (--breakpoint-tablet-sm) {
      position: relative;
      padding: 24px 0;
      margin: 0 -10px;
      border-radius: 0 0 15px 15px;
      overflow: hidden;

      &::before {
        content: none;
      }
    }
  }
}
