.extra-info-banner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 80px;
    background-color: var(--color-white-md);
    border-radius: 10px;
    color: var(--color-black);
    
    &__content {
        padding: 20px;
    }
    
    &__head {
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2em;
    }

    &__paragraph {
        padding-bottom: 16px;
    }

    &__link {
        color: var(--color-black);
        text-decoration: underline;
    }

    &__image--mobile {
        @media(--breakpoint-tablet-sm-up) {
            display: none;
        }
    }

    &__image--desktop {
        display: none;

        @media(--breakpoint-tablet-sm-up) {
            display: block;
        }
    }

    @media(--breakpoint-tablet-sm-up) {
        flex-direction: row;
        padding-right: 16px;
    }
}