#rebrand {
    .field input,
    .field select { 
        display: block; 
        max-width: 100%; 
        width: 100%; 
        height: 40px; 
        padding: 11px; 
        border: 1px solid var(--redesign-color-border);
        border-radius: 100px;
        background: var(--color-white); 
        font-size: 14px; 
        line-height: 20px; 
        color: var(--color-gray-dk);
    }

    .field textarea {
        height: 158px; 
        border-radius: 10px;
    }

    .field--smaller textarea {
        height: 80px;
    }

    .field input:focus,
    .field select:focus,
    .field textarea:focus, 
    .field input:hover,
    .field select:hover,
    .field textarea:hover {
        border-color: var(--redesign-color-primary);
    }

    .field input:not(:placeholder-shown),
    .field select:not(:placeholder-shown),
    .field textarea:not(:placeholder-shown) {
        border-color: var(--color-gray-dk);
        color: var(--redesign-color-black-md);
    }

    .field input.disabled,
    .field select.disabled,
    .field textarea.disabled,
    .field input:disabled,
    .field select:disabled,
    .field textarea:disabled{
        border-color: var(--color-white-md);
        background-color: var(--color-white-md);
        color: var(--color-gray-dk);
        cursor: url(/images/disabled-btn-pointer.svg) 16 16, auto;
    }

    .field.field--error input,
    .field.field--error select,
    .field.field--error textarea {
        border-color: var(--error);

        &:hover, &:focus { 
            border-color: var(--error); 
        }
    }
}