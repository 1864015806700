.promo-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 0;
    pointer-events: none;

    &__head {
        color: var(--color-white);
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2em;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 40vh;
        padding: 40px 32px 20px 32px;
        background-color: var(--redesign-color-primary);
        border-radius: 17px 17px 0 0;
    }

    &__warning {
        display: flex;
        align-items: center;
        gap: 7px;
        padding: 16px;
        background-color: var(--redesign-color-green-dk);
        font-weight: 600;
        font-size: 12px;
        line-height: 1.2em;
        color: var(--color-white);

        @media(--breakpoint-tablet-up) {
            justify-content: center;
        }
    }

    &__btn-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media(--breakpoint-tablet-up) {
            flex-direction: row-reverse;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 16px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    &__img {
        position: absolute;
        bottom: 100%;
        left: 0;
        max-height: 300px;
        transform: translateY(55px);
        z-index: -1;

        @media(--breakpoint-desktop-up) {
            max-height: 596px;
            transform: translate(40%, 0);
            bottom: 0;
            right: 0;
            left: unset;
        }
    }

    &.active {
        opacity: 1;
        z-index: 102;
        pointer-events: all;
    }

    @media(--breakpoint-tablet-up) {
        left: 50%;
        width: 100%;
        max-width: 850px;
        transform: translateX(-50%);
    }
}