.custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-checkbox > input {
    position: absolute;
    top: 20px;
    left: 10px;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;
  }

  .custom-checkbox__checkmark {
    position: relative;
    display: inline-block;
    min-height: 20px;
    min-width: 20px;
    background-color: var(--color-white);
    border: 1px solid;
    border-color: var(--color-neutral-mid);
    border-radius: 4px;
  }

  .custom-checkbox input:checked ~ .custom-checkbox__checkmark {
    background-color: var(--color-primary);
  }

  .custom-checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-checkbox input:checked ~ .custom-checkbox__checkmark:after {
    display: block;
  }

  .custom-checkbox .custom-checkbox__checkmark:after {
    left: 7.5px;
    top: 2.5px;
    width: 5px;
    height: 11px;
    border: solid var(--color-white);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }